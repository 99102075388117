import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
};

export type Account = {
  __typename?: 'Account';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  sortOrder: Scalars['Int'];
  accountType: AccountType;
  accountHolder: AccountHolder;
  enabled: Scalars['Boolean'];
  label: Scalars['String'];
  percentAdditionalInfo?: Maybe<Scalars['String']>;
  labelAdditionalInfo?: Maybe<Scalars['String']>;
  deletable: Scalars['Boolean'];
  setupable: Scalars['Boolean'];
  goalAmount?: Maybe<Scalars['Int']>;
  goalYears?: Maybe<Scalars['Int']>;
  percent: Scalars['Int'];
  estimateAnnualAmount?: Maybe<Scalars['Int']>;
  monthly: Scalars['Int'];
  annual: Scalars['Int'];
  bankFeedPercent?: Maybe<Scalars['Int']>;
  bankFeedAnnual: Scalars['Int'];
};

export type AccountClass = {
  __typename?: 'AccountClass';
  type: Scalars['String'];
  product: Scalars['String'];
};

export enum AccountHolder {
  Person1 = 'Person1',
  Person2 = 'Person2'
}

export type AccountSuggestion = {
  __typename?: 'AccountSuggestion';
  id: Scalars['ID'];
  label: Scalars['String'];
  labelAdditionalInfo?: Maybe<Scalars['String']>;
  type: AccountSuggestionType;
};

export enum AccountSuggestionType {
  Everyday2 = 'Everyday2',
  Fun2 = 'Fun2',
  Goal = 'Goal'
}

export enum AccountType {
  Bills = 'Bills',
  FutureAndMortgage = 'FutureAndMortgage',
  Everyday = 'Everyday',
  Everyday2 = 'Everyday2',
  Fun = 'Fun',
  Fun2 = 'Fun2',
  Holidays = 'Holidays',
  Goal = 'Goal'
}

export type BasiqAccountData = {
  __typename?: 'BasiqAccountData';
  type: Scalars['String'];
  data: Array<BasiqAccountType>;
};

export type BasiqAccountType = {
  __typename?: 'BasiqAccountType';
  type: Scalars['String'];
  id: Scalars['String'];
  accountNo: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  class?: Maybe<AccountClass>;
  balance?: Maybe<Scalars['String']>;
  availableFunds?: Maybe<Scalars['String']>;
  lastUpdated?: Maybe<Scalars['String']>;
  transactionIntervals?: Maybe<Array<TransactionIntervalsClass>>;
  institution?: Maybe<Scalars['String']>;
  accountHolder?: Maybe<Scalars['String']>;
  connection?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type BasiqCategoryDataType = {
  __typename?: 'BasiqCategoryDataType';
  classCode: Scalars['String'];
  classTitle: Scalars['String'];
  divisionTitle: Scalars['String'];
  changeHistory: Array<ChangeHistory>;
  avgMonthly: Scalars['Float'];
};

export type BasiqCategoryJsonType = {
  __typename?: 'BasiqCategoryJsonType';
  basiqCategoryData: Array<BasiqCategoryDataType>;
  totalAvgAmount: Scalars['Float'];
  createdDate: Scalars['DateTime'];
};

export type BasiqPaymentSubCatType = {
  __typename?: 'BasiqPaymentSubCatType';
  category: CategoryType;
  summary: Scalars['String'];
  changeHistory: Array<ChangeHistory>;
};

export type BasiqPaymentType = {
  __typename?: 'BasiqPaymentType';
  avgMonthly: Scalars['String'];
  division: Scalars['String'];
  percentageTotal: Scalars['Float'];
  subCategory: Array<BasiqPaymentSubCatType>;
};

export type BasiqUserConsentDataType = {
  __typename?: 'BasiqUserConsentDataType';
  type: Scalars['String'];
  id: Scalars['String'];
  status: Scalars['String'];
  created: Scalars['String'];
  updated: Scalars['String'];
  expiryDate: Scalars['String'];
};

export type BasiqUserConsentType = {
  __typename?: 'BasiqUserConsentType';
  type: Scalars['String'];
  size: Scalars['Float'];
  data: Array<BasiqUserConsentDataType>;
};

export type BasiqUserDetailType = {
  __typename?: 'BasiqUserDetailType';
  type: Scalars['String'];
  email: Scalars['String'];
  mobile: Scalars['String'];
};

export type BasiqUserExpenseData = {
  __typename?: 'BasiqUserExpenseData';
  type: Scalars['String'];
  id: Scalars['String'];
  fromMonth: Scalars['String'];
  toMonth: Scalars['String'];
  coverageDays: Scalars['String'];
  bankFees?: Maybe<CommonCashFeesLoanType>;
  cashWithdrawals?: Maybe<CommonCashFeesLoanType>;
  loanInterests?: Maybe<CommonCashFeesLoanType>;
  loanRepayments?: Maybe<CommonCashFeesLoanType>;
  externalTransfers?: Maybe<CommonCashFeesLoanType>;
  payments: Array<BasiqPaymentType>;
  links: LinkType;
};

export type BasiqUserExpenseDataType = {
  __typename?: 'BasiqUserExpenseDataType';
  id: Scalars['String'];
  userId: Scalars['String'];
  label: Scalars['String'];
  category: Scalars['String'];
  frequency?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  histroyInfo: Array<BasiqUserExpenseLogDataType>;
};

export type BasiqUserExpenseLogDataType = {
  __typename?: 'BasiqUserExpenseLogDataType';
  id: Scalars['String'];
  expense_id: Scalars['String'];
  amount: Scalars['Float'];
  basiqCategoryJson: BasiqCategoryJsonType;
  created_at: Scalars['DateTime'];
};

export type Bill = {
  __typename?: 'Bill';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  label: Scalars['String'];
  editable: Scalars['Boolean'];
  sortOrder: Scalars['Int'];
  category: BillCategory;
  frequency?: Maybe<BillFrequency>;
  amount?: Maybe<Scalars['Int']>;
  rules?: Maybe<Scalars['String']>;
  annual?: Maybe<Scalars['Int']>;
  basiqCategoryJson?: Maybe<BasiqCategoryJsonType>;
};

export enum BillCategory {
  FinancialCommitments = 'FinancialCommitments',
  Home = 'Home',
  Utilities = 'Utilities',
  Education = 'Education',
  Health = 'Health',
  Transport = 'Transport',
  Other = 'Other'
}

export enum BillFrequency {
  Weekly = 'Weekly',
  Fortnightly = 'Fortnightly',
  Monthly = 'Monthly',
  Quarterly = 'Quarterly',
  Yearly = 'Yearly'
}

export type BrokerInfo = {
  __typename?: 'BrokerInfo';
  name?: Maybe<Scalars['String']>;
  brokerCalendlyLink?: Maybe<Scalars['String']>;
};

export type CommonCashFeesLoanType = {
  __typename?: 'CommonCashFeesLoanType';
  avgMonthly: Scalars['String'];
  summary: Scalars['String'];
  changeHistory: Array<ChangeHistory>;
};

export type CreateBillInput = {
  label: Scalars['String'];
  category: BillCategory;
  frequency: BillFrequency;
  amount: Scalars['Int'];
};

export type CreateEverydayExpenseInput = {
  label: Scalars['String'];
  category: EverydayExpenseCategory;
  amount?: Maybe<Scalars['Int']>;
};

export type CreateFunExpenseInput = {
  label: Scalars['String'];
  category: FunExpenseCategory;
  amount: Scalars['Int'];
  frequency: BillFrequency;
};

export type CreateHolidayInput = {
  label: Scalars['String'];
  amount: Scalars['Int'];
  frequency: Scalars['Int'];
};

export type CreateIncomeStreamInput = {
  label: Scalars['String'];
  frequency: BillFrequency;
  hecsHelp: HecsHelp;
  /** The net income amount (not gross) */
  amount: Scalars['Float'];
};

export type CreateInvestmentPropertyReturnType = {
  __typename?: 'CreateInvestmentPropertyReturnType';
  user: User;
  createdProperty: InvestmentProperty;
};

export type CustomerObjType = {
  __typename?: 'CustomerObjType';
  allow_direct_debit?: Maybe<Scalars['Boolean']>;
  auto_collection?: Maybe<Scalars['String']>;
  card_status?: Maybe<Scalars['String']>;
  created_at: Scalars['Int'];
  deleted?: Maybe<Scalars['Boolean']>;
  excess_payments?: Maybe<Scalars['Int']>;
  first_name?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  last_name?: Maybe<Scalars['String']>;
  net_term_days?: Maybe<Scalars['Int']>;
  pii_cleared?: Maybe<Scalars['String']>;
  preferred_currency_code?: Maybe<Scalars['String']>;
  promotional_credits?: Maybe<Scalars['Int']>;
  refundable_credits?: Maybe<Scalars['Int']>;
  taxability?: Maybe<Scalars['String']>;
  unbilled_charges?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['Int']>;
};

export type CustomerSubscriptionList = {
  __typename?: 'CustomerSubscriptionList';
  list: Array<CustomerWithSubscription>;
};

export type CustomerWithSubscription = {
  __typename?: 'CustomerWithSubscription';
  id: Scalars['String'];
  customer: CustomerObjType;
  subscription: SubscriptionObjType;
};


export enum DayOfWeek {
  Mondays = 'Mondays',
  Tuesdays = 'Tuesdays',
  Wednesdays = 'Wednesdays',
  Thursdays = 'Thursdays',
  Fridays = 'Fridays',
  Saturdays = 'Saturdays',
  Sundays = 'Sundays'
}

export type DefaultValues = {
  __typename?: 'DefaultValues';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  type: DefaultValuesEnum;
  value: Scalars['String'];
};

export enum DefaultValuesEnum {
  Interestrate = 'INTERESTRATE',
  Broker = 'BROKER'
}

export type DefaultValuesInput = {
  type: DefaultValuesEnum;
  value: Scalars['String'];
};

export type EditBillInput = {
  label?: Maybe<Scalars['String']>;
  frequency?: Maybe<BillFrequency>;
  amount?: Maybe<Scalars['Int']>;
};

export type EditEverydayExpenseInput = {
  label?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
};

export type EditFunExpenseInput = {
  label: Scalars['String'];
  category?: Maybe<FunExpenseCategory>;
  amount?: Maybe<Scalars['Int']>;
  frequency?: Maybe<BillFrequency>;
};

export type EditGoalInput = {
  term?: Maybe<GoalTerm>;
  key?: Maybe<GoalKey>;
  label?: Maybe<Scalars['String']>;
  isPartnerGoal: Scalars['Boolean'];
};

export type EditHolidayInput = {
  label: Scalars['String'];
  amount: Scalars['Int'];
  frequency: Scalars['Int'];
};

export type EditIncomeStreamInput = {
  label?: Maybe<Scalars['String']>;
  frequency: BillFrequency;
  incomeType: IncomeType;
  hecsHelp: HecsHelp;
  /** The net income amount (not gross) */
  amount: Scalars['Float'];
};

export type EditInvestmentPropertyInput = {
  name?: Maybe<Scalars['String']>;
  vacancyRateAmount?: Maybe<Scalars['Float']>;
  grossRentalAmount?: Maybe<Scalars['Int']>;
  rentalFrequency?: Maybe<BillFrequency>;
  mortgages: Array<EditInvestmentPropertyMortgageInput>;
  agentCommissionAmount?: Maybe<Scalars['Float']>;
  councilRatesAmount?: Maybe<Scalars['Int']>;
  councilRatesFrequency?: Maybe<BillFrequency>;
  waterRatesAmount?: Maybe<Scalars['Int']>;
  waterRatesFrequency?: Maybe<BillFrequency>;
  bodyFeesAmount?: Maybe<Scalars['Int']>;
  bodyFeesFrequency?: Maybe<BillFrequency>;
  landlordInsuranceAmount?: Maybe<Scalars['Int']>;
  landlordInsuranceFrequency?: Maybe<BillFrequency>;
  maintenanceAmount?: Maybe<Scalars['Int']>;
  maintenanceFrequency?: Maybe<BillFrequency>;
  lettingFeesAmount?: Maybe<Scalars['Int']>;
  lettingFeesFrequency?: Maybe<BillFrequency>;
  landTaxAmount?: Maybe<Scalars['Int']>;
  landTaxFrequency?: Maybe<BillFrequency>;
  otherAmount?: Maybe<Scalars['Int']>;
  otherFrequency?: Maybe<BillFrequency>;
  fnfAmount?: Maybe<Scalars['Int']>;
  buildingAmount?: Maybe<Scalars['Int']>;
  person1Proportion?: Maybe<Scalars['Int']>;
  propertyValue?: Maybe<Scalars['Int']>;
  targetLvr: Scalars['Int'];
  keepVsSell: KeepVsSell;
  useEquity: UseEquity;
  isCompanyTrust?: Maybe<Scalars['Boolean']>;
};

export type EditInvestmentPropertyMortgageInput = {
  id?: Maybe<Scalars['String']>;
  interestRate: Scalars['Float'];
  principle: Scalars['Int'];
  mortgage_type: MortgageType;
  accountOffset?: Maybe<Scalars['Int']>;
};

export type EditMortgageSplitInput = {
  id?: Maybe<Scalars['String']>;
  mortgageAmount?: Maybe<Scalars['Int']>;
  mortgageInterest?: Maybe<Scalars['Float']>;
  mortgage_type: MortgageType;
  accountOffset?: Maybe<Scalars['Int']>;
};

export type EditUserInfoInput = {
  name?: Maybe<Scalars['String']>;
  partnerName?: Maybe<Scalars['String']>;
  relationshipStatus?: Maybe<RelationshipStatus>;
  dependents?: Maybe<Scalars['Int']>;
  livingSituation?: Maybe<LivingSituation>;
  basicRentAmount?: Maybe<Scalars['Int']>;
  basicRentFrequency?: Maybe<BillFrequency>;
  mortgageAmount?: Maybe<Scalars['Int']>;
  mortgageInterest?: Maybe<Scalars['Float']>;
  mortgage_type?: Maybe<MortgageType>;
  accountOffset?: Maybe<Scalars['Int']>;
  mortgageRepaymentMonthly?: Maybe<Scalars['Int']>;
  propertyValue?: Maybe<Scalars['Int']>;
  targetLvr?: Maybe<Scalars['Int']>;
  oldBankName?: Maybe<Scalars['String']>;
  newBankName?: Maybe<Scalars['String']>;
  savingsBankName?: Maybe<Scalars['String']>;
  estimateEverydayAmount1?: Maybe<Scalars['Int']>;
  estimateEverydayAmount2?: Maybe<Scalars['Int']>;
  estimateFunAmount1?: Maybe<Scalars['Int']>;
  estimateFunAmount2?: Maybe<Scalars['Int']>;
  paymentScheduleBasedOn?: Maybe<AccountHolder>;
  everydayPayday1?: Maybe<Scalars['String']>;
  everydayPayday2?: Maybe<Scalars['String']>;
  personalityType1?: Maybe<PersonalityType>;
  personalityType2?: Maybe<PersonalityType>;
  hasSetupAccounts?: Maybe<Scalars['Boolean']>;
  hasSecondBank?: Maybe<Scalars['Boolean']>;
  hasScheduledTransfers?: Maybe<Scalars['Boolean']>;
  keepVsSell?: Maybe<KeepVsSell>;
  brokerId?: Maybe<Scalars['String']>;
  brokerCalendlyLink?: Maybe<Scalars['String']>;
  brokerNumber?: Maybe<Scalars['String']>;
};

export type EverydayExpense = {
  __typename?: 'EverydayExpense';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  label: Scalars['String'];
  category: EverydayExpenseCategory;
  amount?: Maybe<Scalars['Int']>;
  sortOrder: Scalars['Int'];
  annual?: Maybe<Scalars['Int']>;
  basiqCategoryJson?: Maybe<BasiqCategoryJsonType>;
};

export enum EverydayExpenseCategory {
  Shopping = 'Shopping',
  Entertainment = 'Entertainment',
  EatingOut = 'EatingOut'
}

export enum FbhType {
  Yes = 'Yes',
  No = 'No'
}

export type FunExpense = {
  __typename?: 'FunExpense';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  label: Scalars['String'];
  category: FunExpenseCategory;
  amount?: Maybe<Scalars['Int']>;
  frequency?: Maybe<BillFrequency>;
  sortOrder: Scalars['Int'];
  amountAdditionalInfo?: Maybe<Scalars['String']>;
  frequencyAdditionalInfo?: Maybe<Scalars['String']>;
  annual: Scalars['Int'];
  basiqCategoryJson?: Maybe<BasiqCategoryJsonType>;
};

export enum FunExpenseCategory {
  Shopping = 'Shopping',
  Entertainment = 'Entertainment'
}

export type Goal = {
  __typename?: 'Goal';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  term: GoalTerm;
  key: GoalKey;
  label: Scalars['String'];
  isPartnerGoal: Scalars['Boolean'];
  completed_on?: Maybe<Scalars['DateTime']>;
  archived_on?: Maybe<Scalars['DateTime']>;
};

export type GoalInput = {
  term: GoalTerm;
  key: GoalKey;
  label: Scalars['String'];
  isPartnerGoal: Scalars['Boolean'];
};

export enum GoalKey {
  Holidays = 'Holidays',
  RelationshipAndFamily = 'Relationship_and_Family',
  OwnHome = 'Own_Home',
  IncomeCareer = 'Income_Career',
  InvestmentProperty = 'Investment_Property',
  Celebrations = 'Celebrations',
  BigThings = 'Big_Things'
}

export enum GoalTerm {
  ShortTerm = 'ShortTerm',
  MediumTerm = 'MediumTerm',
  LongTerm = 'LongTerm'
}

export enum HecsHelp {
  Yes = 'Yes',
  No = 'No'
}

export type Holiday = {
  __typename?: 'Holiday';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  sortOrder: Scalars['Int'];
  label: Scalars['String'];
  amount: Scalars['Int'];
  frequency: Scalars['Int'];
  annual: Scalars['Int'];
  basiqCategoryJson?: Maybe<BasiqCategoryJsonType>;
};

export type HolidaySuggestion = {
  __typename?: 'HolidaySuggestion';
  id: Scalars['ID'];
  label: Scalars['String'];
  frequency: Scalars['Int'];
  amount: Scalars['Int'];
  goal: Goal;
};

export type Income = {
  __typename?: 'Income';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  sortOrder: Scalars['Int'];
  label: Scalars['String'];
  accountHolder: AccountHolder;
  isBasicPay: Scalars['Boolean'];
  editable: Scalars['Boolean'];
  frequency: BillFrequency;
  incomeType: IncomeType;
  amount: Scalars['Float'];
  hecsHelp: HecsHelp;
  annual: Scalars['Float'];
  grossPayEquiv: Scalars['Float'];
};

export enum IncomeType {
  Net = 'Net',
  Gross = 'Gross'
}

export type InvestmentProperty = {
  __typename?: 'InvestmentProperty';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  mortgages: Array<InvestmentPropertyMortgage>;
  vacancyRateAmount: Scalars['Float'];
  grossRentalAmount: Scalars['Int'];
  rentalFrequency: BillFrequency;
  name: Scalars['String'];
  councilRatesAmount?: Maybe<Scalars['Int']>;
  councilRatesFrequency: BillFrequency;
  waterRatesAmount?: Maybe<Scalars['Int']>;
  waterRatesFrequency: BillFrequency;
  bodyFeesAmount?: Maybe<Scalars['Int']>;
  bodyFeesFrequency: BillFrequency;
  landlordInsuranceAmount?: Maybe<Scalars['Int']>;
  landlordInsuranceFrequency: BillFrequency;
  maintenanceAmount?: Maybe<Scalars['Int']>;
  maintenanceFrequency: BillFrequency;
  lettingFeesAmount?: Maybe<Scalars['Int']>;
  lettingFeesFrequency: BillFrequency;
  landTaxAmount?: Maybe<Scalars['Int']>;
  landTaxFrequency: BillFrequency;
  otherAmount?: Maybe<Scalars['Int']>;
  otherFrequency: BillFrequency;
  agentCommissionAmount?: Maybe<Scalars['Float']>;
  fnfAmount?: Maybe<Scalars['Int']>;
  buildingAmount?: Maybe<Scalars['Int']>;
  person1Proportion: Scalars['Int'];
  person2Proportion: Scalars['Int'];
  propertyValue?: Maybe<Scalars['Int']>;
  targetLvr: Scalars['Int'];
  keepVsSell: KeepVsSell;
  useEquity: UseEquity;
  isCompanyTrust?: Maybe<Scalars['Boolean']>;
  propertyYield: Scalars['Float'];
  lvr: Scalars['Float'];
  salesProceeds: Scalars['Int'];
  sellingCost: Scalars['Int'];
  useableEquity: Scalars['Float'];
  expensesAnnual: Scalars['Int'];
  mortgageCostAnnual: Scalars['Int'];
  grossRentalAnnual: Scalars['Int'];
  vacancyRateAnnual: Scalars['Int'];
  councilRatesAnnual: Scalars['Int'];
  waterRatesAnnual: Scalars['Int'];
  bodyFeesAnnual: Scalars['Int'];
  landlordInsuranceAnnual: Scalars['Int'];
  maintenanceAnnual: Scalars['Int'];
  lettingFeesAnnual: Scalars['Int'];
  landTaxAnnual: Scalars['Int'];
  otherAnnual: Scalars['Int'];
  agentCommissionAnnual: Scalars['Int'];
  fnfAnnual: Scalars['Int'];
  buildingAnnual: Scalars['Int'];
  depreciationsAnnual: Scalars['Int'];
  taxableAnnual: Scalars['Int'];
  annual: Scalars['Int'];
  taxPaidPerson1: Scalars['Int'];
  taxPaidPerson2: Scalars['Int'];
  annualAmountPerson1: Scalars['Float'];
  annualAmountPerson2: Scalars['Float'];
};

export type InvestmentPropertyMortgage = {
  __typename?: 'InvestmentPropertyMortgage';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  interestRate?: Maybe<Scalars['Float']>;
  principle?: Maybe<Scalars['Int']>;
  accountOffset?: Maybe<Scalars['Int']>;
  mortgage_type?: Maybe<MortgageType>;
  mortgageSplit: Array<MortgageSplit>;
  mortgageNetPrinciple: Scalars['Int'];
};

export type JwtUser = {
  __typename?: 'JwtUser';
  id: Scalars['String'];
  email: Scalars['String'];
  role: UserRole;
};

export enum KeepVsSell {
  Keep = 'Keep',
  Sell = 'Sell'
}

export type LinkType = {
  __typename?: 'LinkType';
  self: Scalars['String'];
  accounts: Array<Scalars['String']>;
};

export enum LivingSituation {
  Renting = 'Renting',
  LivingWithFamily = 'LivingWithFamily',
  OwnHome = 'OwnHome'
}

export enum LmiWaiverType {
  None = 'None',
  MedicalProfessional = 'MedicalProfessional',
  Accountant = 'Accountant',
  Lawyer = 'Lawyer',
  Engineer = 'Engineer',
  ItProfessional = 'ItProfessional',
  BankStaff = 'BankStaff',
  NoLmi = 'NoLmi',
  HomeGuarantee = 'HomeGuarantee'
}

export type MortgageSplit = {
  __typename?: 'MortgageSplit';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  accountOffset?: Maybe<Scalars['Int']>;
  mortgageAmount?: Maybe<Scalars['Int']>;
  mortgageInterest?: Maybe<Scalars['Float']>;
  mortgage_type?: Maybe<MortgageType>;
};

export enum MortgageType {
  InterestOnly = 'InterestOnly',
  PrincipalAndInterest = 'PrincipalAndInterest'
}

export type Mutation = {
  __typename?: 'Mutation';
  signUp: SignUpPayload;
  signIn: SignInPayload;
  updatePassword: UserPayload;
  resetPassword: Scalars['String'];
  claimHostedPage: User;
  createBasiqUser: UserPayload;
  basiqAcceptsTerms: UserPayload;
  netPayForGross: Scalars['Int'];
  grossPayForNet: Scalars['Int'];
  editPlannedPurchase: UserPayload;
  addPlannedPurchase: UserPayload;
  updatePlannedPurchase: UserPayload;
  addUserPlannedPurchase: UserPayload;
  undoPlannedPurchase: UserPayload;
  editUserInfo: UserPayload;
  addUserMortgageSplit: UserPayload;
  editUserMortgageSplit: UserPayload;
  deleteUserMortgageSplit: UserPayload;
  addUserIncome: UserPayload;
  editUserIncome: UserPayload;
  deleteUserIncome: UserPayload;
  addUserBill: UserPayload;
  editUserBill: UserPayload;
  deleteUserBill: UserPayload;
  addUserEverydayExpense: UserPayload;
  editUserEveryday: UserPayload;
  deleteUserEveryday: UserPayload;
  addUserFunExpense: UserPayload;
  editUserFun: UserPayload;
  deleteUserFun: UserPayload;
  addUserHoliday: UserPayload;
  editUserHoliday: UserPayload;
  deleteUserHoliday: UserPayload;
  addUserGoal: UserPayload;
  editUserGoal: UserPayload;
  deleteUserGoal: UserPayload;
  goalCompleted: UserPayload;
  goalArchived: UserPayload;
  enableAccount: UserPayload;
  disableAccount: UserPayload;
  editGoalAccount: UserPayload;
  editEstimateAnnualAmount: UserPayload;
  editInvestmentProperty: UserPayload;
  addInvestmentProperty: CreateInvestmentPropertyReturnType;
  addInvestmentMortgageSplit: UserPayload;
  editInvestmentMortgageSplit: UserPayload;
  editProjectedSaving: UserPayload;
  deleteInvestmentMortgageSplit: UserPayload;
  deleteInvestmentProperty: UserPayload;
  updateInvestmentProperty: UserPayload;
  editUserRole: UserPayload;
  editUserOnboardingFinished: UserPayload;
  editUserPersonalCoachStatus: UserPayload;
  deleteUser: Scalars['Int'];
  editDefaultValue: UserPayload;
};


export type MutationSignUpArgs = {
  input: SignUpInput;
};


export type MutationSignInArgs = {
  input: SignInInput;
};


export type MutationUpdatePasswordArgs = {
  input: UpdatePasswordInput;
};


export type MutationResetPasswordArgs = {
  userId: Scalars['String'];
};


export type MutationClaimHostedPageArgs = {
  type: Scalars['String'];
  id: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationCreateBasiqUserArgs = {
  mobile: Scalars['String'];
  email: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationBasiqAcceptsTermsArgs = {
  userId: Scalars['String'];
};


export type MutationNetPayForGrossArgs = {
  amount: Scalars['Int'];
};


export type MutationGrossPayForNetArgs = {
  amount: Scalars['Int'];
};


export type MutationEditPlannedPurchaseArgs = {
  input: PlannedPurchaseEditInput;
  plannedPurchaseId: Scalars['String'];
};


export type MutationAddPlannedPurchaseArgs = {
  userId: Scalars['String'];
};


export type MutationUpdatePlannedPurchaseArgs = {
  input: PlannedPurchaseUpdateInput;
  plannedPurchaseId: Scalars['String'];
};


export type MutationAddUserPlannedPurchaseArgs = {
  userId: Scalars['String'];
};


export type MutationUndoPlannedPurchaseArgs = {
  userId: Scalars['String'];
};


export type MutationEditUserInfoArgs = {
  input: EditUserInfoInput;
  userId: Scalars['String'];
};


export type MutationAddUserMortgageSplitArgs = {
  noOfSplits: Scalars['Int'];
  userId: Scalars['String'];
};


export type MutationEditUserMortgageSplitArgs = {
  input: EditMortgageSplitInput;
  mortgageSplitId: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationDeleteUserMortgageSplitArgs = {
  mortgageSplitId: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationAddUserIncomeArgs = {
  input: CreateIncomeStreamInput;
  userId: Scalars['String'];
};


export type MutationEditUserIncomeArgs = {
  input: EditIncomeStreamInput;
  incomeId: Scalars['String'];
};


export type MutationDeleteUserIncomeArgs = {
  incomeId: Scalars['String'];
};


export type MutationAddUserBillArgs = {
  input: CreateBillInput;
  userId: Scalars['String'];
};


export type MutationEditUserBillArgs = {
  input: EditBillInput;
  billId: Scalars['String'];
};


export type MutationDeleteUserBillArgs = {
  billId: Scalars['String'];
};


export type MutationAddUserEverydayExpenseArgs = {
  input: CreateEverydayExpenseInput;
  accountHolder: AccountHolder;
  userId: Scalars['String'];
};


export type MutationEditUserEverydayArgs = {
  input: EditEverydayExpenseInput;
  everydayId: Scalars['String'];
};


export type MutationDeleteUserEverydayArgs = {
  everydayId: Scalars['String'];
};


export type MutationAddUserFunExpenseArgs = {
  input: CreateFunExpenseInput;
  accountHolder: AccountHolder;
  userId: Scalars['String'];
};


export type MutationEditUserFunArgs = {
  input: EditFunExpenseInput;
  funId: Scalars['String'];
};


export type MutationDeleteUserFunArgs = {
  funId: Scalars['String'];
};


export type MutationAddUserHolidayArgs = {
  input: CreateHolidayInput;
  userId: Scalars['String'];
};


export type MutationEditUserHolidayArgs = {
  input: EditHolidayInput;
  holidayId: Scalars['String'];
};


export type MutationDeleteUserHolidayArgs = {
  holidayId: Scalars['String'];
};


export type MutationAddUserGoalArgs = {
  input: GoalInput;
  userId: Scalars['String'];
};


export type MutationEditUserGoalArgs = {
  input: EditGoalInput;
  goalId: Scalars['String'];
};


export type MutationDeleteUserGoalArgs = {
  goalId: Scalars['String'];
};


export type MutationGoalCompletedArgs = {
  goalId: Scalars['String'];
};


export type MutationGoalArchivedArgs = {
  goalId: Scalars['String'];
};


export type MutationEnableAccountArgs = {
  accountId: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationDisableAccountArgs = {
  accountId: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationEditGoalAccountArgs = {
  goalYears: Scalars['Int'];
  goalAmount: Scalars['Int'];
  accountId: Scalars['String'];
};


export type MutationEditEstimateAnnualAmountArgs = {
  estimateAnnualAmount?: Maybe<Scalars['Int']>;
  accountId: Scalars['String'];
};


export type MutationEditInvestmentPropertyArgs = {
  propertyInput: EditInvestmentPropertyInput;
  propertyId: Scalars['String'];
};


export type MutationAddInvestmentPropertyArgs = {
  userId: Scalars['String'];
};


export type MutationAddInvestmentMortgageSplitArgs = {
  noOfSplits: Scalars['Int'];
  propertyId: Scalars['String'];
};


export type MutationEditInvestmentMortgageSplitArgs = {
  input: EditMortgageSplitInput;
  mortgageSplitId: Scalars['String'];
  propertyId: Scalars['String'];
};


export type MutationEditProjectedSavingArgs = {
  input: ProjectedSavingEditInput;
  userId: Scalars['String'];
};


export type MutationDeleteInvestmentMortgageSplitArgs = {
  mortgageSplitId: Scalars['String'];
  propertyId: Scalars['String'];
};


export type MutationDeleteInvestmentPropertyArgs = {
  propertyId: Scalars['String'];
};


export type MutationUpdateInvestmentPropertyArgs = {
  propertyInput: UpdateInvestmentPropertyInput;
  propertyId: Scalars['String'];
};


export type MutationEditUserRoleArgs = {
  role: UserRole;
  userId: Scalars['String'];
};


export type MutationEditUserOnboardingFinishedArgs = {
  isOnboardingFinished: Scalars['Boolean'];
  userId: Scalars['String'];
};


export type MutationEditUserPersonalCoachStatusArgs = {
  personalCoachStatus: PersonalCoachStatus;
  userId: Scalars['String'];
};


export type MutationDeleteUserArgs = {
  userId: Scalars['String'];
};


export type MutationEditDefaultValueArgs = {
  input: DefaultValuesInput;
  userId: Scalars['String'];
};

export type PaginationInput = {
  /** Starts at page 1 */
  page: Scalars['Int'];
  perPage: Scalars['Int'];
};

export enum PersonalCoachStatus {
  NoSubscription = 'NO_SUBSCRIPTION',
  SubscriptionPersonalCoach = 'SUBSCRIPTION_PERSONAL_COACH',
  SubscriptionExpired = 'SUBSCRIPTION_EXPIRED'
}

export enum PersonalityType {
  TightArse = 'TightArse',
  Saver = 'Saver',
  Balanced = 'Balanced',
  Spender = 'Spender',
  Shopaholic = 'Shopaholic'
}

export type PlannedPurchase = {
  __typename?: 'PlannedPurchase';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  state: StateType;
  fbh: FbhType;
  purchasePrice: Scalars['Int'];
  purchaseLoanType: PurchaseLoanAmountType;
  loanAmountPercentage: Scalars['Int'];
  bankFees: Scalars['Int'];
  lenderLegals: Scalars['Int'];
  lenderFees: Scalars['Int'];
  refinanceCosts: Scalars['Int'];
  otherFees: Scalars['Int'];
  depositPaid: Scalars['Int'];
  savings: Scalars['Int'];
  gift: Scalars['Int'];
  shares: Scalars['Int'];
  equity: Scalars['Int'];
  interestRate?: Maybe<Scalars['Float']>;
  repaymentFrequency?: Maybe<BillFrequency>;
  bankLoanType?: Maybe<MortgageType>;
  interestRate2?: Maybe<Scalars['Float']>;
  bankLoanType2?: Maybe<MortgageType>;
  personalLoan: Scalars['Int'];
  landValue: Scalars['Int'];
  propertyType?: Maybe<PropertyType>;
  plannedPurchaseType?: Maybe<Scalars['String']>;
  lmiWaiver?: Maybe<LmiWaiverType>;
  getPriceOnPropertyType: Scalars['Float'];
  stampDuty: Scalars['Float'];
  registrationFees: Scalars['Float'];
  sellingCosts: Scalars['Float'];
  loanAmountExcludingLmi: Scalars['Float'];
  mortgageInsurance: Scalars['Float'];
  newLoan: Scalars['Float'];
  fundsRequired: Scalars['Float'];
  fundsAvailable: Scalars['Float'];
  netFunds: Scalars['Float'];
  salesProceeds: Scalars['Float'];
  repaymentAmount: Scalars['Float'];
  repaymentAmount2: Scalars['Float'];
  totalLoanAmount: Scalars['Float'];
  avgLoanInterest: Scalars['Float'];
  totalRepaymentAmount: Scalars['Float'];
};

export type PlannedPurchaseEditInput = {
  state: StateType;
  fbh: FbhType;
  purchasePrice: Scalars['Int'];
  purchaseLoanType: PurchaseLoanAmountType;
  loanAmountPercentage: Scalars['Int'];
  bankFees: Scalars['Int'];
  lenderLegals: Scalars['Int'];
  lenderFees: Scalars['Int'];
  refinanceCosts: Scalars['Int'];
  otherFees: Scalars['Int'];
  depositPaid: Scalars['Int'];
  savings: Scalars['Int'];
  gift: Scalars['Int'];
  shares: Scalars['Int'];
  equity: Scalars['Int'];
  personalLoan: Scalars['Int'];
  interestRate?: Maybe<Scalars['Float']>;
  interestRate2?: Maybe<Scalars['Float']>;
  repaymentFrequency?: Maybe<BillFrequency>;
  bankLoanType?: Maybe<MortgageType>;
  bankLoanType2?: Maybe<MortgageType>;
  landValue: Scalars['Int'];
  propertyType?: Maybe<PropertyType>;
  plannedPurchaseType?: Maybe<Scalars['String']>;
  lmiWaiver?: Maybe<LmiWaiverType>;
};

export type PlannedPurchaseUpdateInput = {
  depositPaid: Scalars['Int'];
};

export type ProjectedSavingEditInput = {
  saving_year?: Maybe<Scalars['Float']>;
  loanRemainingYears: Scalars['Int'];
};

export enum PropertyType {
  Established = 'Established',
  OffThePlan = 'OffThePlan',
  HouseAndLand = 'HouseAndLand',
  Land = 'Land'
}

export enum PurchaseLoanAmountType {
  Percentage = 'Percentage',
  Amount = 'Amount'
}

export type Query = {
  __typename?: 'Query';
  otpVerify: SignUpPayload;
  forgotPassword: Scalars['String'];
  updateResetPassword: Scalars['String'];
  getBasiqConsentUrl?: Maybe<Scalars['String']>;
  getBasiqUserDetails: BasiqUserDetailType;
  verifyBasiqConsent: BasiqUserConsentType;
  getUserBasiqAccounts: BasiqAccountData;
  getUserBasiqExpenses: BasiqUserExpenseData;
  getBasiqExpenseLog: BasiqUserExpenseDataType;
  checkoutHostedPage?: Maybe<Scalars['String']>;
  /** This value can get expensive to compute, so query sparingly. Value is returned as a JSON string, to be parsed and then provided to chargebee */
  portalSessionObject?: Maybe<Scalars['String']>;
  users: UserSummaryResponse;
  getAllUsersList: UsersListResponse;
  getBrokerAllUsersList: UsersListResponse;
  getUserDetails: UserDetailsWithSubscription;
  getBrokerUsersList: UsersListResponse;
  verifyAndUpdateSubscription: Scalars['String'];
  user: User;
  defaultValueList: Array<DefaultValues>;
};


export type QueryOtpVerifyArgs = {
  userId: Scalars['String'];
  otp: Scalars['String'];
};


export type QueryForgotPasswordArgs = {
  email: Scalars['String'];
};


export type QueryUpdateResetPasswordArgs = {
  token: Scalars['String'];
  password: Scalars['String'];
};


export type QueryGetBasiqConsentUrlArgs = {
  type: Scalars['String'];
  userId: Scalars['String'];
};


export type QueryGetBasiqUserDetailsArgs = {
  userId: Scalars['String'];
};


export type QueryVerifyBasiqConsentArgs = {
  userId: Scalars['String'];
};


export type QueryGetUserBasiqAccountsArgs = {
  userId: Scalars['String'];
};


export type QueryGetUserBasiqExpensesArgs = {
  userId: Scalars['String'];
};


export type QueryGetBasiqExpenseLogArgs = {
  expenseId: Scalars['String'];
  expenseType: Scalars['String'];
  userId: Scalars['String'];
};


export type QueryCheckoutHostedPageArgs = {
  frequency: Scalars['String'];
  type: Scalars['String'];
};


export type QueryUsersArgs = {
  pagination?: Maybe<PaginationInput>;
  sort?: Maybe<SortInput>;
  filter?: Maybe<Scalars['String']>;
};


export type QueryGetAllUsersListArgs = {
  pagination?: Maybe<PaginationInput>;
  sort?: Maybe<SortInput>;
  filter?: Maybe<Scalars['String']>;
};


export type QueryGetBrokerAllUsersListArgs = {
  pagination?: Maybe<PaginationInput>;
  sort?: Maybe<SortInput>;
  filter?: Maybe<Scalars['String']>;
};


export type QueryGetUserDetailsArgs = {
  userId: Scalars['String'];
};


export type QueryGetBrokerUsersListArgs = {
  pagination?: Maybe<PaginationInput>;
};


export type QueryVerifyAndUpdateSubscriptionArgs = {
  type: Scalars['String'];
  subscriptionId: Scalars['String'];
  userId: Scalars['String'];
};


export type QueryUserArgs = {
  id: Scalars['String'];
};


export type QueryDefaultValueListArgs = {
  userId: Scalars['String'];
};

export enum RelationshipStatus {
  Single = 'Single',
  Couple = 'Couple'
}

export type SetupTask = {
  __typename?: 'SetupTask';
  complete: Scalars['Boolean'];
  label: Scalars['String'];
  description: Scalars['String'];
  prop: UserInfoTaskProp;
};

export type SignInInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type SignInPayload = {
  __typename?: 'SignInPayload';
  token: Scalars['String'];
  user: JwtUser;
};

export type SignUpInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  acceptsMarketing: Scalars['Boolean'];
  acceptsTerms: Scalars['Boolean'];
};

export type SignUpPayload = {
  __typename?: 'SignUpPayload';
  user: User;
  token: Scalars['String'];
};

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type SortInput = {
  field: Scalars['String'];
  direction: SortDirection;
};

export enum StateType {
  Nsw = 'NSW',
  Vic = 'VIC',
  Qld = 'QLD',
  Sa = 'SA',
  Wa = 'WA',
  Nt = 'NT',
  Act = 'ACT',
  Tas = 'TAS'
}

export type SubscriptionItems = {
  __typename?: 'SubscriptionItems';
  amount: Scalars['Float'];
  free_quantity?: Maybe<Scalars['Int']>;
  item_price_id: Scalars['String'];
  item_type?: Maybe<Scalars['String']>;
  quantity: Scalars['Int'];
  unit_price: Scalars['Float'];
};

export type SubscriptionObjType = {
  __typename?: 'SubscriptionObjType';
  activated_at?: Maybe<Scalars['Int']>;
  billing_period?: Maybe<Scalars['Int']>;
  billing_period_unit?: Maybe<Scalars['String']>;
  created_at: Scalars['Int'];
  currency_code: Scalars['String'];
  current_term_end?: Maybe<Scalars['Int']>;
  current_term_start?: Maybe<Scalars['Int']>;
  customer_id: Scalars['String'];
  id: Scalars['String'];
  next_billing_at?: Maybe<Scalars['Int']>;
  status: Scalars['String'];
  subscription_items: Array<SubscriptionItems>;
  updated_at?: Maybe<Scalars['Int']>;
};

export type Transfer = {
  __typename?: 'Transfer';
  id: Scalars['ID'];
  transferAmount: Scalars['Int'];
  transferFrequency: BillFrequency;
  timing: Scalars['String'];
  fromBank: Scalars['String'];
  toBank: Scalars['String'];
  account: Account;
};

export type UpdateInvestmentPropertyInput = {
  targetLvr?: Maybe<Scalars['Int']>;
  keepVsSell?: Maybe<KeepVsSell>;
  propertyValue?: Maybe<Scalars['Int']>;
  useEquity?: Maybe<UseEquity>;
};

export type UpdatePasswordInput = {
  userId: Scalars['String'];
  currentPassword: Scalars['String'];
  password: Scalars['String'];
};

export enum UseEquity {
  Yes = 'Yes',
  No = 'No'
}

export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  investmentProperties: Array<InvestmentProperty>;
  subscriptionTrialEnd?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  role: UserRole;
  personalCoachStatus: PersonalCoachStatus;
  acceptsMarketing: Scalars['Boolean'];
  acceptsTerms: Scalars['Boolean'];
  basiqId?: Maybe<Scalars['String']>;
  basiqAcceptsTerms: Scalars['Boolean'];
  basiqConsent: Scalars['Boolean'];
  isOnboardingFinished: Scalars['Boolean'];
  emailVerify: Scalars['Boolean'];
  basiqFetchedAt?: Maybe<Scalars['DateTime']>;
  isBasiqConnected: Scalars['Boolean'];
  userInfo: UserInfo;
  plannedPurchase?: Maybe<PlannedPurchase>;
  bills: Array<Bill>;
  income: Array<Income>;
  everyday: Array<EverydayExpense>;
  everyday2: Array<EverydayExpense>;
  holidays: Array<Holiday>;
  fun: Array<FunExpense>;
  fun2: Array<FunExpense>;
  goals: Array<Goal>;
};

export type UserDetailsWithSubscription = {
  __typename?: 'UserDetailsWithSubscription';
  user: UserSummary;
  subscriptionData: CustomerSubscriptionList;
};

export type UserInfo = {
  __typename?: 'UserInfo';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  saving_year: Scalars['Int'];
  loanRemainingYears: Scalars['Int'];
  mortgage_type?: Maybe<MortgageType>;
  incomeWithoutRentalIncome: Scalars['Int'];
  displayAnnualRent?: Maybe<Scalars['Int']>;
  grandTotalAnnual: Scalars['Int'];
  accountsAnnualTotal: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  partnerName?: Maybe<Scalars['String']>;
  relationshipStatus?: Maybe<RelationshipStatus>;
  personalityType1?: Maybe<PersonalityType>;
  personalityType2?: Maybe<PersonalityType>;
  dependents: Scalars['Int'];
  livingSituation?: Maybe<LivingSituation>;
  basicRentAmount?: Maybe<Scalars['Int']>;
  basicRentFrequency?: Maybe<BillFrequency>;
  mortgageAmount?: Maybe<Scalars['Int']>;
  mortgageSplit: Array<MortgageSplit>;
  mortgageInterest: Scalars['Float'];
  accountOffset?: Maybe<Scalars['Int']>;
  mortgagePrinciple: Scalars['Int'];
  mortgageRepaymentMonthly?: Maybe<Scalars['Int']>;
  mortgageYearsRemaining: Scalars['Float'];
  projected_savings?: Maybe<Scalars['Int']>;
  propertyValue?: Maybe<Scalars['Int']>;
  targetLvr: Scalars['Int'];
  useableEquity: Scalars['Float'];
  paydayFrequency: BillFrequency;
  setupTasks: Array<SetupTask>;
  hasEveryday2: Scalars['Boolean'];
  hasFun2: Scalars['Boolean'];
  oldBankName?: Maybe<Scalars['String']>;
  newBankName?: Maybe<Scalars['String']>;
  savingsBankName?: Maybe<Scalars['String']>;
  everydayPayday1: DayOfWeek;
  everydayPayday2: DayOfWeek;
  keepVsSell?: Maybe<KeepVsSell>;
  brokerId?: Maybe<Scalars['String']>;
  brokerCalendlyLink?: Maybe<Scalars['String']>;
  brokerNumber?: Maybe<Scalars['String']>;
  brokerInfo?: Maybe<BrokerInfo>;
  accounts: Array<Account>;
  extraAccountSuggestions: Array<AccountSuggestion>;
  extraHolidaySuggestions: Array<HolidaySuggestion>;
  transfers: Array<Transfer>;
  accountsAnnualUnallocated: Scalars['Int'];
  proposedMortgageRepaymentMonthly: Scalars['Int'];
  plannedPurchaseNoOfMonths: Scalars['Float'];
  allLoanClearInYears: Scalars['Float'];
  allInvestmentLoanAvgInterest: Scalars['Float'];
};

export type UserInfoName = {
  __typename?: 'UserInfoName';
  name?: Maybe<Scalars['String']>;
  brokerId?: Maybe<Scalars['String']>;
};

export enum UserInfoTaskProp {
  HasSetupAccounts = 'hasSetupAccounts',
  HasSecondBank = 'hasSecondBank',
  HasScheduledTransfers = 'hasScheduledTransfers'
}

export type UserListSummary = {
  __typename?: 'UserListSummary';
  id: Scalars['ID'];
  email: Scalars['String'];
  role: UserRole;
  subscriptionId?: Maybe<Scalars['String']>;
  personalCoachStatus: PersonalCoachStatus;
  personalCoachSubscriptionId?: Maybe<Scalars['String']>;
  userInfo: UserInfoName;
};

export type UserPayload = {
  __typename?: 'UserPayload';
  user: User;
};

export enum UserRole {
  Admin = 'ADMIN',
  NoSubscription = 'NO_SUBSCRIPTION',
  SubscriptionPremium = 'SUBSCRIPTION_PREMIUM',
  SubscriptionExpired = 'SUBSCRIPTION_EXPIRED',
  Broker = 'BROKER'
}

export type UserSummary = {
  __typename?: 'UserSummary';
  id: Scalars['ID'];
  email: Scalars['String'];
  role: UserRole;
  subscriptionId?: Maybe<Scalars['String']>;
  personalCoachStatus: PersonalCoachStatus;
  personalCoachSubscriptionId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type UserSummaryResponse = {
  __typename?: 'UserSummaryResponse';
  users: Array<UserSummary>;
  total: Scalars['Int'];
};

export type UsersListResponse = {
  __typename?: 'UsersListResponse';
  users: Array<UserListSummary>;
  total: Scalars['Int'];
  currentPage: Scalars['Int'];
};

export type CategoryType = {
  __typename?: 'categoryType';
  expenseClass: ExpenseClassType;
};

export type ChangeHistory = {
  __typename?: 'changeHistory';
  date: Scalars['String'];
  amount: Scalars['String'];
};

export type ExpenseClassType = {
  __typename?: 'expenseClassType';
  classCode: Scalars['String'];
  classTitle: Scalars['String'];
  divisionCode: Scalars['String'];
  divisionTitle: Scalars['String'];
};

export type TransactionIntervalsClass = {
  __typename?: 'transactionIntervalsClass';
  from: Scalars['String'];
  to: Scalars['String'];
};

export type BankAccountTypeFragment = (
  { __typename?: 'BasiqAccountData' }
  & Pick<BasiqAccountData, 'type'>
  & { data: Array<(
    { __typename?: 'BasiqAccountType' }
    & Pick<BasiqAccountType, 'type' | 'id' | 'accountHolder' | 'accountNo' | 'availableFunds' | 'balance' | 'connection' | 'currency' | 'institution' | 'lastUpdated' | 'name' | 'status'>
    & { class?: Maybe<(
      { __typename?: 'AccountClass' }
      & Pick<AccountClass, 'product' | 'type'>
    )>, transactionIntervals?: Maybe<Array<(
      { __typename?: 'transactionIntervalsClass' }
      & Pick<TransactionIntervalsClass, 'from' | 'to'>
    )>> }
  )> }
);

export type BankUserExpenseTypeFragment = (
  { __typename?: 'BasiqUserExpenseData' }
  & Pick<BasiqUserExpenseData, 'type' | 'id' | 'fromMonth' | 'toMonth' | 'coverageDays'>
  & { bankFees?: Maybe<(
    { __typename?: 'CommonCashFeesLoanType' }
    & Pick<CommonCashFeesLoanType, 'avgMonthly' | 'summary'>
    & { changeHistory: Array<(
      { __typename?: 'changeHistory' }
      & Pick<ChangeHistory, 'amount' | 'date'>
    )> }
  )>, cashWithdrawals?: Maybe<(
    { __typename?: 'CommonCashFeesLoanType' }
    & Pick<CommonCashFeesLoanType, 'avgMonthly' | 'summary'>
    & { changeHistory: Array<(
      { __typename?: 'changeHistory' }
      & Pick<ChangeHistory, 'amount' | 'date'>
    )> }
  )>, externalTransfers?: Maybe<(
    { __typename?: 'CommonCashFeesLoanType' }
    & Pick<CommonCashFeesLoanType, 'avgMonthly' | 'summary'>
    & { changeHistory: Array<(
      { __typename?: 'changeHistory' }
      & Pick<ChangeHistory, 'amount' | 'date'>
    )> }
  )>, loanInterests?: Maybe<(
    { __typename?: 'CommonCashFeesLoanType' }
    & Pick<CommonCashFeesLoanType, 'avgMonthly' | 'summary'>
    & { changeHistory: Array<(
      { __typename?: 'changeHistory' }
      & Pick<ChangeHistory, 'amount' | 'date'>
    )> }
  )>, loanRepayments?: Maybe<(
    { __typename?: 'CommonCashFeesLoanType' }
    & Pick<CommonCashFeesLoanType, 'avgMonthly' | 'summary'>
    & { changeHistory: Array<(
      { __typename?: 'changeHistory' }
      & Pick<ChangeHistory, 'amount' | 'date'>
    )> }
  )>, payments: Array<(
    { __typename?: 'BasiqPaymentType' }
    & Pick<BasiqPaymentType, 'avgMonthly' | 'division' | 'percentageTotal'>
    & { subCategory: Array<(
      { __typename?: 'BasiqPaymentSubCatType' }
      & Pick<BasiqPaymentSubCatType, 'summary'>
      & { category: (
        { __typename?: 'categoryType' }
        & { expenseClass: (
          { __typename?: 'expenseClassType' }
          & Pick<ExpenseClassType, 'classCode' | 'classTitle' | 'divisionCode' | 'divisionTitle'>
        ) }
      ), changeHistory: Array<(
        { __typename?: 'changeHistory' }
        & Pick<ChangeHistory, 'amount' | 'date'>
      )> }
    )> }
  )>, links: (
    { __typename?: 'LinkType' }
    & Pick<LinkType, 'self' | 'accounts'>
  ) }
);

export type MeUserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'email' | 'role' | 'acceptsMarketing' | 'acceptsTerms' | 'personalCoachStatus' | 'isBasiqConnected' | 'basiqAcceptsTerms' | 'basiqConsent' | 'isOnboardingFinished' | 'basiqFetchedAt'>
  & { userInfo: (
    { __typename?: 'UserInfo' }
    & MeUserInfoFragment
  ), goals: Array<(
    { __typename?: 'Goal' }
    & Pick<Goal, 'id' | 'key' | 'label' | 'isPartnerGoal' | 'term' | 'createdAt' | 'updatedAt' | 'completed_on' | 'archived_on'>
  )>, income: Array<(
    { __typename?: 'Income' }
    & Pick<Income, 'id' | 'sortOrder' | 'label' | 'amount' | 'annual' | 'frequency' | 'grossPayEquiv' | 'editable' | 'incomeType' | 'hecsHelp' | 'accountHolder' | 'isBasicPay' | 'createdAt' | 'updatedAt'>
  )>, bills: Array<(
    { __typename?: 'Bill' }
    & Pick<Bill, 'id' | 'sortOrder' | 'label' | 'category' | 'frequency' | 'amount' | 'annual' | 'rules' | 'editable'>
    & { basiqCategoryJson?: Maybe<(
      { __typename?: 'BasiqCategoryJsonType' }
      & Pick<BasiqCategoryJsonType, 'totalAvgAmount'>
      & { basiqCategoryData: Array<(
        { __typename?: 'BasiqCategoryDataType' }
        & Pick<BasiqCategoryDataType, 'classCode' | 'classTitle' | 'divisionTitle' | 'avgMonthly'>
        & { changeHistory: Array<(
          { __typename?: 'changeHistory' }
          & Pick<ChangeHistory, 'date' | 'amount'>
        )> }
      )> }
    )> }
  )>, everyday: Array<(
    { __typename?: 'EverydayExpense' }
    & Pick<EverydayExpense, 'id' | 'amount' | 'annual' | 'category' | 'label' | 'sortOrder'>
    & { basiqCategoryJson?: Maybe<(
      { __typename?: 'BasiqCategoryJsonType' }
      & Pick<BasiqCategoryJsonType, 'totalAvgAmount'>
      & { basiqCategoryData: Array<(
        { __typename?: 'BasiqCategoryDataType' }
        & Pick<BasiqCategoryDataType, 'classCode' | 'classTitle' | 'divisionTitle' | 'avgMonthly'>
        & { changeHistory: Array<(
          { __typename?: 'changeHistory' }
          & Pick<ChangeHistory, 'date' | 'amount'>
        )> }
      )> }
    )> }
  )>, everyday2: Array<(
    { __typename?: 'EverydayExpense' }
    & Pick<EverydayExpense, 'id' | 'amount' | 'annual' | 'category' | 'label' | 'sortOrder'>
  )>, fun: Array<(
    { __typename?: 'FunExpense' }
    & Pick<FunExpense, 'id' | 'amount' | 'frequency' | 'annual' | 'category' | 'label' | 'sortOrder' | 'amountAdditionalInfo' | 'frequencyAdditionalInfo'>
    & { basiqCategoryJson?: Maybe<(
      { __typename?: 'BasiqCategoryJsonType' }
      & Pick<BasiqCategoryJsonType, 'totalAvgAmount'>
      & { basiqCategoryData: Array<(
        { __typename?: 'BasiqCategoryDataType' }
        & Pick<BasiqCategoryDataType, 'classCode' | 'classTitle' | 'divisionTitle' | 'avgMonthly'>
        & { changeHistory: Array<(
          { __typename?: 'changeHistory' }
          & Pick<ChangeHistory, 'date' | 'amount'>
        )> }
      )> }
    )> }
  )>, fun2: Array<(
    { __typename?: 'FunExpense' }
    & Pick<FunExpense, 'id' | 'amount' | 'frequency' | 'annual' | 'category' | 'label' | 'sortOrder' | 'amountAdditionalInfo' | 'frequencyAdditionalInfo'>
  )>, holidays: Array<(
    { __typename?: 'Holiday' }
    & Pick<Holiday, 'id' | 'sortOrder' | 'label' | 'amount' | 'annual' | 'frequency' | 'createdAt' | 'updatedAt'>
    & { basiqCategoryJson?: Maybe<(
      { __typename?: 'BasiqCategoryJsonType' }
      & Pick<BasiqCategoryJsonType, 'totalAvgAmount'>
      & { basiqCategoryData: Array<(
        { __typename?: 'BasiqCategoryDataType' }
        & Pick<BasiqCategoryDataType, 'classCode' | 'classTitle' | 'divisionTitle' | 'avgMonthly'>
        & { changeHistory: Array<(
          { __typename?: 'changeHistory' }
          & Pick<ChangeHistory, 'date' | 'amount'>
        )> }
      )> }
    )> }
  )>, investmentProperties: Array<(
    { __typename?: 'InvestmentProperty' }
    & Pick<InvestmentProperty, 'id' | 'name' | 'annual' | 'expensesAnnual' | 'depreciationsAnnual' | 'taxableAnnual' | 'vacancyRateAmount' | 'vacancyRateAnnual' | 'grossRentalAnnual' | 'grossRentalAmount' | 'rentalFrequency' | 'mortgageCostAnnual' | 'councilRatesAmount' | 'councilRatesFrequency' | 'waterRatesAmount' | 'waterRatesFrequency' | 'bodyFeesAmount' | 'bodyFeesFrequency' | 'landlordInsuranceAmount' | 'landlordInsuranceFrequency' | 'maintenanceAmount' | 'maintenanceFrequency' | 'lettingFeesAmount' | 'lettingFeesFrequency' | 'landTaxAmount' | 'landTaxFrequency' | 'otherAmount' | 'otherFrequency' | 'agentCommissionAmount' | 'councilRatesAnnual' | 'waterRatesAnnual' | 'bodyFeesAnnual' | 'landlordInsuranceAnnual' | 'maintenanceAnnual' | 'lettingFeesAnnual' | 'agentCommissionAnnual' | 'landTaxAnnual' | 'otherAnnual' | 'fnfAmount' | 'fnfAnnual' | 'buildingAmount' | 'buildingAnnual' | 'person1Proportion' | 'person2Proportion' | 'taxPaidPerson1' | 'taxPaidPerson2' | 'annualAmountPerson1' | 'annualAmountPerson2' | 'propertyValue' | 'targetLvr' | 'propertyYield' | 'lvr' | 'useableEquity' | 'keepVsSell' | 'useEquity' | 'isCompanyTrust' | 'salesProceeds' | 'sellingCost' | 'createdAt' | 'updatedAt'>
    & { mortgages: Array<(
      { __typename: 'InvestmentPropertyMortgage' }
      & Pick<InvestmentPropertyMortgage, 'id' | 'interestRate' | 'principle' | 'accountOffset' | 'mortgage_type' | 'mortgageNetPrinciple' | 'createdAt' | 'updatedAt'>
      & { mortgageSplit: Array<(
        { __typename?: 'MortgageSplit' }
        & Pick<MortgageSplit, 'id' | 'mortgageAmount' | 'mortgageInterest' | 'accountOffset' | 'mortgage_type' | 'createdAt' | 'updatedAt'>
      )> }
    )> }
  )>, plannedPurchase?: Maybe<(
    { __typename?: 'PlannedPurchase' }
    & Pick<PlannedPurchase, 'id' | 'state' | 'fbh' | 'purchasePrice' | 'purchaseLoanType' | 'loanAmountPercentage' | 'bankFees' | 'lenderLegals' | 'lenderFees' | 'refinanceCosts' | 'otherFees' | 'depositPaid' | 'savings' | 'gift' | 'shares' | 'equity' | 'personalLoan' | 'stampDuty' | 'registrationFees' | 'sellingCosts' | 'loanAmountExcludingLmi' | 'mortgageInsurance' | 'newLoan' | 'fundsRequired' | 'fundsAvailable' | 'netFunds' | 'interestRate' | 'interestRate2' | 'repaymentFrequency' | 'bankLoanType' | 'bankLoanType2' | 'repaymentAmount' | 'repaymentAmount2' | 'landValue' | 'propertyType' | 'totalLoanAmount' | 'avgLoanInterest' | 'totalRepaymentAmount' | 'salesProceeds' | 'plannedPurchaseType' | 'lmiWaiver'>
  )> }
);

export type MeUserInfoFragment = (
  { __typename?: 'UserInfo' }
  & Pick<UserInfo, 'id' | 'name' | 'partnerName' | 'grandTotalAnnual' | 'accountsAnnualTotal' | 'accountsAnnualUnallocated' | 'saving_year' | 'mortgage_type' | 'projected_savings' | 'loanRemainingYears' | 'propertyValue' | 'targetLvr' | 'useableEquity' | 'incomeWithoutRentalIncome' | 'displayAnnualRent' | 'dependents' | 'livingSituation' | 'relationshipStatus' | 'basicRentAmount' | 'basicRentFrequency' | 'mortgagePrinciple' | 'mortgageYearsRemaining' | 'mortgageAmount' | 'mortgageInterest' | 'accountOffset' | 'mortgageRepaymentMonthly' | 'proposedMortgageRepaymentMonthly' | 'plannedPurchaseNoOfMonths' | 'allLoanClearInYears' | 'paydayFrequency' | 'hasEveryday2' | 'hasFun2' | 'oldBankName' | 'newBankName' | 'savingsBankName' | 'everydayPayday1' | 'everydayPayday2' | 'personalityType1' | 'personalityType2' | 'keepVsSell' | 'brokerId' | 'brokerCalendlyLink' | 'brokerNumber'>
  & { accounts: Array<(
    { __typename?: 'Account' }
    & Pick<Account, 'id' | 'enabled' | 'accountType' | 'accountHolder' | 'annual' | 'estimateAnnualAmount' | 'deletable' | 'goalAmount' | 'goalYears' | 'label' | 'monthly' | 'percent' | 'percentAdditionalInfo' | 'setupable' | 'sortOrder' | 'createdAt' | 'updatedAt' | 'bankFeedPercent' | 'bankFeedAnnual'>
  )>, mortgageSplit: Array<(
    { __typename?: 'MortgageSplit' }
    & Pick<MortgageSplit, 'id' | 'accountOffset' | 'mortgageAmount' | 'mortgageInterest' | 'mortgage_type'>
  )>, extraAccountSuggestions: Array<(
    { __typename?: 'AccountSuggestion' }
    & Pick<AccountSuggestion, 'id' | 'label' | 'labelAdditionalInfo' | 'type'>
  )>, extraHolidaySuggestions: Array<(
    { __typename?: 'HolidaySuggestion' }
    & Pick<HolidaySuggestion, 'id' | 'label' | 'frequency' | 'amount'>
  )>, transfers: Array<(
    { __typename?: 'Transfer' }
    & Pick<Transfer, 'id' | 'fromBank' | 'toBank' | 'timing' | 'transferAmount' | 'transferFrequency'>
    & { account: (
      { __typename?: 'Account' }
      & Pick<Account, 'id' | 'label' | 'accountType' | 'accountHolder'>
    ) }
  )>, setupTasks: Array<(
    { __typename?: 'SetupTask' }
    & Pick<SetupTask, 'prop' | 'complete' | 'label' | 'description'>
  )>, brokerInfo?: Maybe<(
    { __typename?: 'BrokerInfo' }
    & Pick<BrokerInfo, 'name' | 'brokerCalendlyLink'>
  )> }
);

export type AddInvestmentMortgageSplitMutationVariables = Exact<{
  propertyId: Scalars['String'];
  noOfSplits: Scalars['Int'];
}>;


export type AddInvestmentMortgageSplitMutation = (
  { __typename?: 'Mutation' }
  & { addInvestmentMortgageSplit: (
    { __typename?: 'UserPayload' }
    & { user: (
      { __typename?: 'User' }
      & MeUserFragment
    ) }
  ) }
);

export type AddPlannedPurchaseMutationVariables = Exact<{
  userId: Scalars['String'];
}>;


export type AddPlannedPurchaseMutation = (
  { __typename?: 'Mutation' }
  & { addPlannedPurchase: (
    { __typename?: 'UserPayload' }
    & { user: (
      { __typename?: 'User' }
      & MeUserFragment
    ) }
  ) }
);

export type AddUserMortgageSplitMutationVariables = Exact<{
  userId: Scalars['String'];
  noOfSplits: Scalars['Int'];
}>;


export type AddUserMortgageSplitMutation = (
  { __typename?: 'Mutation' }
  & { addUserMortgageSplit: (
    { __typename?: 'UserPayload' }
    & { user: (
      { __typename?: 'User' }
      & MeUserFragment
    ) }
  ) }
);

export type AddUserPlannedPurchaseMutationVariables = Exact<{
  userId: Scalars['String'];
}>;


export type AddUserPlannedPurchaseMutation = (
  { __typename?: 'Mutation' }
  & { addUserPlannedPurchase: (
    { __typename?: 'UserPayload' }
    & { user: (
      { __typename?: 'User' }
      & MeUserFragment
    ) }
  ) }
);

export type AnnualAmountEstimateEditMutationVariables = Exact<{
  accountId: Scalars['String'];
  newAmount?: Maybe<Scalars['Int']>;
}>;


export type AnnualAmountEstimateEditMutation = (
  { __typename?: 'Mutation' }
  & { editEstimateAnnualAmount: (
    { __typename?: 'UserPayload' }
    & { user: (
      { __typename?: 'User' }
      & MeUserFragment
    ) }
  ) }
);

export type BasiqAcceptsTermsMutationVariables = Exact<{
  userId: Scalars['String'];
}>;


export type BasiqAcceptsTermsMutation = (
  { __typename?: 'Mutation' }
  & { basiqAcceptsTerms: (
    { __typename?: 'UserPayload' }
    & { user: (
      { __typename?: 'User' }
      & MeUserFragment
    ) }
  ) }
);

export type BillAddMutationVariables = Exact<{
  userId: Scalars['String'];
  input: CreateBillInput;
}>;


export type BillAddMutation = (
  { __typename?: 'Mutation' }
  & { addUserBill: (
    { __typename?: 'UserPayload' }
    & { user: (
      { __typename?: 'User' }
      & MeUserFragment
    ) }
  ) }
);

export type BillDeleteMutationVariables = Exact<{
  billId: Scalars['String'];
}>;


export type BillDeleteMutation = (
  { __typename?: 'Mutation' }
  & { deleteUserBill: (
    { __typename?: 'UserPayload' }
    & { user: (
      { __typename?: 'User' }
      & MeUserFragment
    ) }
  ) }
);

export type BillEditMutationVariables = Exact<{
  billId: Scalars['String'];
  input: EditBillInput;
}>;


export type BillEditMutation = (
  { __typename?: 'Mutation' }
  & { editUserBill: (
    { __typename?: 'UserPayload' }
    & { user: (
      { __typename?: 'User' }
      & MeUserFragment
    ) }
  ) }
);

export type ClaimHostedPageMutationVariables = Exact<{
  userId: Scalars['String'];
  id: Scalars['String'];
  type: Scalars['String'];
}>;


export type ClaimHostedPageMutation = (
  { __typename?: 'Mutation' }
  & { claimHostedPage: (
    { __typename?: 'User' }
    & MeUserFragment
  ) }
);

export type CreateUserBasiqAccountMutationVariables = Exact<{
  userId: Scalars['String'];
  email: Scalars['String'];
  mobile: Scalars['String'];
}>;


export type CreateUserBasiqAccountMutation = (
  { __typename?: 'Mutation' }
  & { createBasiqUser: (
    { __typename?: 'UserPayload' }
    & { user: (
      { __typename?: 'User' }
      & MeUserFragment
    ) }
  ) }
);

export type EditDefaultValueMutationVariables = Exact<{
  userId: Scalars['String'];
  input: DefaultValuesInput;
}>;


export type EditDefaultValueMutation = (
  { __typename?: 'Mutation' }
  & { editDefaultValue: (
    { __typename?: 'UserPayload' }
    & { user: (
      { __typename?: 'User' }
      & MeUserFragment
    ) }
  ) }
);

export type DefaultValueListQueryVariables = Exact<{
  userId: Scalars['String'];
}>;


export type DefaultValueListQuery = (
  { __typename?: 'Query' }
  & { defaultValueList: Array<(
    { __typename?: 'DefaultValues' }
    & Pick<DefaultValues, 'id' | 'type' | 'value'>
  )> }
);

export type DeleteInvestmentMortgageSplitMutationVariables = Exact<{
  propertyId: Scalars['String'];
  mortgageSplitId: Scalars['String'];
}>;


export type DeleteInvestmentMortgageSplitMutation = (
  { __typename?: 'Mutation' }
  & { deleteInvestmentMortgageSplit: (
    { __typename?: 'UserPayload' }
    & { user: (
      { __typename?: 'User' }
      & MeUserFragment
    ) }
  ) }
);

export type DeleteUserMortgageSplitMutationVariables = Exact<{
  userId: Scalars['String'];
  mortgageSplitId: Scalars['String'];
}>;


export type DeleteUserMortgageSplitMutation = (
  { __typename?: 'Mutation' }
  & { deleteUserMortgageSplit: (
    { __typename?: 'UserPayload' }
    & { user: (
      { __typename?: 'User' }
      & MeUserFragment
    ) }
  ) }
);

export type DisableAccountMutationVariables = Exact<{
  userId: Scalars['String'];
  accountId: Scalars['String'];
}>;


export type DisableAccountMutation = (
  { __typename?: 'Mutation' }
  & { disableAccount: (
    { __typename?: 'UserPayload' }
    & { user: (
      { __typename?: 'User' }
      & MeUserFragment
    ) }
  ) }
);

export type EditInvestmentMortgageSplitMutationVariables = Exact<{
  propertyId: Scalars['String'];
  mortgageSplitId: Scalars['String'];
  input: EditMortgageSplitInput;
}>;


export type EditInvestmentMortgageSplitMutation = (
  { __typename?: 'Mutation' }
  & { editInvestmentMortgageSplit: (
    { __typename?: 'UserPayload' }
    & { user: (
      { __typename?: 'User' }
      & MeUserFragment
    ) }
  ) }
);

export type EditPlannedPurchaseMutationVariables = Exact<{
  plannedPurchaseId: Scalars['String'];
  input: PlannedPurchaseEditInput;
}>;


export type EditPlannedPurchaseMutation = (
  { __typename?: 'Mutation' }
  & { editPlannedPurchase: (
    { __typename?: 'UserPayload' }
    & { user: (
      { __typename?: 'User' }
      & MeUserFragment
    ) }
  ) }
);

export type EditUserMortgageSplitMutationVariables = Exact<{
  userId: Scalars['String'];
  mortgageSplitId: Scalars['String'];
  input: EditMortgageSplitInput;
}>;


export type EditUserMortgageSplitMutation = (
  { __typename?: 'Mutation' }
  & { editUserMortgageSplit: (
    { __typename?: 'UserPayload' }
    & { user: (
      { __typename?: 'User' }
      & MeUserFragment
    ) }
  ) }
);

export type EditUserOnboardingFinishedMutationVariables = Exact<{
  userId: Scalars['String'];
  isOnboardingFinished: Scalars['Boolean'];
}>;


export type EditUserOnboardingFinishedMutation = (
  { __typename?: 'Mutation' }
  & { editUserOnboardingFinished: (
    { __typename?: 'UserPayload' }
    & { user: (
      { __typename?: 'User' }
      & MeUserFragment
    ) }
  ) }
);

export type EnableAccountMutationVariables = Exact<{
  userId: Scalars['String'];
  accountId: Scalars['String'];
}>;


export type EnableAccountMutation = (
  { __typename?: 'Mutation' }
  & { enableAccount: (
    { __typename?: 'UserPayload' }
    & { user: (
      { __typename?: 'User' }
      & MeUserFragment
    ) }
  ) }
);

export type EverydayAddMutationVariables = Exact<{
  userId: Scalars['String'];
  accountHolder: AccountHolder;
  input: CreateEverydayExpenseInput;
}>;


export type EverydayAddMutation = (
  { __typename?: 'Mutation' }
  & { addUserEverydayExpense: (
    { __typename?: 'UserPayload' }
    & { user: (
      { __typename?: 'User' }
      & MeUserFragment
    ) }
  ) }
);

export type EverydayDeleteMutationVariables = Exact<{
  everydayId: Scalars['String'];
}>;


export type EverydayDeleteMutation = (
  { __typename?: 'Mutation' }
  & { deleteUserEveryday: (
    { __typename?: 'UserPayload' }
    & { user: (
      { __typename?: 'User' }
      & MeUserFragment
    ) }
  ) }
);

export type EverydayEditMutationVariables = Exact<{
  everydayId: Scalars['String'];
  input: EditEverydayExpenseInput;
}>;


export type EverydayEditMutation = (
  { __typename?: 'Mutation' }
  & { editUserEveryday: (
    { __typename?: 'UserPayload' }
    & { user: (
      { __typename?: 'User' }
      & MeUserFragment
    ) }
  ) }
);

export type FunAddMutationVariables = Exact<{
  userId: Scalars['String'];
  accountHolder: AccountHolder;
  input: CreateFunExpenseInput;
}>;


export type FunAddMutation = (
  { __typename?: 'Mutation' }
  & { addUserFunExpense: (
    { __typename?: 'UserPayload' }
    & { user: (
      { __typename?: 'User' }
      & MeUserFragment
    ) }
  ) }
);

export type FunDeleteMutationVariables = Exact<{
  funId: Scalars['String'];
}>;


export type FunDeleteMutation = (
  { __typename?: 'Mutation' }
  & { deleteUserFun: (
    { __typename?: 'UserPayload' }
    & { user: (
      { __typename?: 'User' }
      & MeUserFragment
    ) }
  ) }
);

export type FunEditMutationVariables = Exact<{
  funId: Scalars['String'];
  input: EditFunExpenseInput;
}>;


export type FunEditMutation = (
  { __typename?: 'Mutation' }
  & { editUserFun: (
    { __typename?: 'UserPayload' }
    & { user: (
      { __typename?: 'User' }
      & MeUserFragment
    ) }
  ) }
);

export type GoalAccountEditMutationVariables = Exact<{
  accountId: Scalars['String'];
  goalYears: Scalars['Int'];
  goalAmount: Scalars['Int'];
}>;


export type GoalAccountEditMutation = (
  { __typename?: 'Mutation' }
  & { editGoalAccount: (
    { __typename?: 'UserPayload' }
    & { user: (
      { __typename?: 'User' }
      & MeUserFragment
    ) }
  ) }
);

export type GoalAddMutationVariables = Exact<{
  userId: Scalars['String'];
  input: GoalInput;
}>;


export type GoalAddMutation = (
  { __typename?: 'Mutation' }
  & { addUserGoal: (
    { __typename?: 'UserPayload' }
    & { user: (
      { __typename?: 'User' }
      & MeUserFragment
    ) }
  ) }
);

export type GoalArchivedMutationVariables = Exact<{
  goalId: Scalars['String'];
}>;


export type GoalArchivedMutation = (
  { __typename?: 'Mutation' }
  & { goalArchived: (
    { __typename?: 'UserPayload' }
    & { user: (
      { __typename?: 'User' }
      & MeUserFragment
    ) }
  ) }
);

export type GoalCompletedMutationVariables = Exact<{
  goalId: Scalars['String'];
}>;


export type GoalCompletedMutation = (
  { __typename?: 'Mutation' }
  & { goalCompleted: (
    { __typename?: 'UserPayload' }
    & { user: (
      { __typename?: 'User' }
      & MeUserFragment
    ) }
  ) }
);

export type DeleteUserGoalMutationVariables = Exact<{
  goalId: Scalars['String'];
}>;


export type DeleteUserGoalMutation = (
  { __typename?: 'Mutation' }
  & { deleteUserGoal: (
    { __typename?: 'UserPayload' }
    & { user: (
      { __typename?: 'User' }
      & MeUserFragment
    ) }
  ) }
);

export type GoalEditMutationVariables = Exact<{
  goalId: Scalars['String'];
  input: EditGoalInput;
}>;


export type GoalEditMutation = (
  { __typename?: 'Mutation' }
  & { editUserGoal: (
    { __typename?: 'UserPayload' }
    & { user: (
      { __typename?: 'User' }
      & MeUserFragment
    ) }
  ) }
);

export type AddUserHolidayMutationVariables = Exact<{
  userId: Scalars['String'];
  input: CreateHolidayInput;
}>;


export type AddUserHolidayMutation = (
  { __typename?: 'Mutation' }
  & { addUserHoliday: (
    { __typename?: 'UserPayload' }
    & { user: (
      { __typename?: 'User' }
      & MeUserFragment
    ) }
  ) }
);

export type HolidayDeleteMutationVariables = Exact<{
  holidayId: Scalars['String'];
}>;


export type HolidayDeleteMutation = (
  { __typename?: 'Mutation' }
  & { deleteUserHoliday: (
    { __typename?: 'UserPayload' }
    & { user: (
      { __typename?: 'User' }
      & MeUserFragment
    ) }
  ) }
);

export type HolidayEditMutationVariables = Exact<{
  holidayId: Scalars['String'];
  input: EditHolidayInput;
}>;


export type HolidayEditMutation = (
  { __typename?: 'Mutation' }
  & { editUserHoliday: (
    { __typename?: 'UserPayload' }
    & { user: (
      { __typename?: 'User' }
      & MeUserFragment
    ) }
  ) }
);

export type IncomeAddMutationVariables = Exact<{
  userId: Scalars['String'];
  input: CreateIncomeStreamInput;
}>;


export type IncomeAddMutation = (
  { __typename?: 'Mutation' }
  & { addUserIncome: (
    { __typename?: 'UserPayload' }
    & { user: (
      { __typename?: 'User' }
      & MeUserFragment
    ) }
  ) }
);

export type IncomeDeleteMutationVariables = Exact<{
  incomeId: Scalars['String'];
}>;


export type IncomeDeleteMutation = (
  { __typename?: 'Mutation' }
  & { deleteUserIncome: (
    { __typename?: 'UserPayload' }
    & { user: (
      { __typename?: 'User' }
      & MeUserFragment
    ) }
  ) }
);

export type IncomeEditMutationVariables = Exact<{
  incomeId: Scalars['String'];
  input: EditIncomeStreamInput;
}>;


export type IncomeEditMutation = (
  { __typename?: 'Mutation' }
  & { editUserIncome: (
    { __typename?: 'UserPayload' }
    & { user: (
      { __typename?: 'User' }
      & MeUserFragment
    ) }
  ) }
);

export type InvestmentPropertyAddMutationVariables = Exact<{
  userId: Scalars['String'];
}>;


export type InvestmentPropertyAddMutation = (
  { __typename?: 'Mutation' }
  & { addInvestmentProperty: (
    { __typename?: 'CreateInvestmentPropertyReturnType' }
    & { user: (
      { __typename?: 'User' }
      & MeUserFragment
    ), createdProperty: (
      { __typename?: 'InvestmentProperty' }
      & Pick<InvestmentProperty, 'id'>
    ) }
  ) }
);

export type InvestmentPropertyDeleteMutationVariables = Exact<{
  propertyId: Scalars['String'];
}>;


export type InvestmentPropertyDeleteMutation = (
  { __typename?: 'Mutation' }
  & { deleteInvestmentProperty: (
    { __typename?: 'UserPayload' }
    & { user: (
      { __typename?: 'User' }
      & MeUserFragment
    ) }
  ) }
);

export type InvestmentPropertyEditMutationVariables = Exact<{
  propertyId: Scalars['String'];
  input: EditInvestmentPropertyInput;
}>;


export type InvestmentPropertyEditMutation = (
  { __typename?: 'Mutation' }
  & { editInvestmentProperty: (
    { __typename?: 'UserPayload' }
    & { user: (
      { __typename?: 'User' }
      & MeUserFragment
    ) }
  ) }
);

export type InvestmentPropertyUpdateMutationVariables = Exact<{
  propertyId: Scalars['String'];
  input: UpdateInvestmentPropertyInput;
}>;


export type InvestmentPropertyUpdateMutation = (
  { __typename?: 'Mutation' }
  & { updateInvestmentProperty: (
    { __typename?: 'UserPayload' }
    & { user: (
      { __typename?: 'User' }
      & MeUserFragment
    ) }
  ) }
);

export type ProjectedSavingEditMutationVariables = Exact<{
  userId: Scalars['String'];
  input: ProjectedSavingEditInput;
}>;


export type ProjectedSavingEditMutation = (
  { __typename?: 'Mutation' }
  & { editProjectedSaving: (
    { __typename?: 'UserPayload' }
    & { user: (
      { __typename?: 'User' }
      & MeUserFragment
    ) }
  ) }
);

export type UndoPlannedPurchaseMutationVariables = Exact<{
  userId: Scalars['String'];
}>;


export type UndoPlannedPurchaseMutation = (
  { __typename?: 'Mutation' }
  & { undoPlannedPurchase: (
    { __typename?: 'UserPayload' }
    & { user: (
      { __typename?: 'User' }
      & MeUserFragment
    ) }
  ) }
);

export type UpdateUserPasswordMutationVariables = Exact<{
  input: UpdatePasswordInput;
}>;


export type UpdateUserPasswordMutation = (
  { __typename?: 'Mutation' }
  & { updatePassword: (
    { __typename?: 'UserPayload' }
    & { user: (
      { __typename?: 'User' }
      & MeUserFragment
    ) }
  ) }
);

export type UserDeleteMutationVariables = Exact<{
  userId: Scalars['String'];
}>;


export type UserDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteUser'>
);

export type UserInfoEditMutationVariables = Exact<{
  userId: Scalars['String'];
  input: EditUserInfoInput;
}>;


export type UserInfoEditMutation = (
  { __typename?: 'Mutation' }
  & { editUserInfo: (
    { __typename?: 'UserPayload' }
    & { user: (
      { __typename?: 'User' }
      & MeUserFragment
    ) }
  ) }
);

export type UserPersoanlCoachStatusEditMutationVariables = Exact<{
  userId: Scalars['String'];
  personalCoachStatus: PersonalCoachStatus;
}>;


export type UserPersoanlCoachStatusEditMutation = (
  { __typename?: 'Mutation' }
  & { editUserPersonalCoachStatus: (
    { __typename?: 'UserPayload' }
    & { user: (
      { __typename?: 'User' }
      & MeUserFragment
    ) }
  ) }
);

export type UserRoleEditMutationVariables = Exact<{
  userId: Scalars['String'];
  role: UserRole;
}>;


export type UserRoleEditMutation = (
  { __typename?: 'Mutation' }
  & { editUserRole: (
    { __typename?: 'UserPayload' }
    & { user: (
      { __typename?: 'User' }
      & MeUserFragment
    ) }
  ) }
);

export type AllUsersQueryVariables = Exact<{
  filter?: Maybe<Scalars['String']>;
  sort?: Maybe<SortInput>;
  pagination?: Maybe<PaginationInput>;
}>;


export type AllUsersQuery = (
  { __typename?: 'Query' }
  & { users: (
    { __typename?: 'UserSummaryResponse' }
    & Pick<UserSummaryResponse, 'total'>
    & { users: Array<(
      { __typename?: 'UserSummary' }
      & Pick<UserSummary, 'id' | 'email' | 'role' | 'personalCoachStatus' | 'name' | 'createdAt' | 'updatedAt'>
    )> }
  ) }
);

export type ForgotPasswordQueryVariables = Exact<{
  email: Scalars['String'];
}>;


export type ForgotPasswordQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'forgotPassword'>
);

export type GetAllUsersListQueryVariables = Exact<{
  filter?: Maybe<Scalars['String']>;
  sort?: Maybe<SortInput>;
  pagination?: Maybe<PaginationInput>;
}>;


export type GetAllUsersListQuery = (
  { __typename?: 'Query' }
  & { getAllUsersList: (
    { __typename?: 'UsersListResponse' }
    & Pick<UsersListResponse, 'total' | 'currentPage'>
    & { users: Array<(
      { __typename?: 'UserListSummary' }
      & Pick<UserListSummary, 'id' | 'email' | 'role' | 'personalCoachStatus'>
      & { userInfo: (
        { __typename?: 'UserInfoName' }
        & Pick<UserInfoName, 'name' | 'brokerId'>
      ) }
    )> }
  ) }
);

export type GetBasiqConsentUrlQueryVariables = Exact<{
  userId: Scalars['String'];
  type: Scalars['String'];
}>;


export type GetBasiqConsentUrlQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getBasiqConsentUrl'>
);

export type GetBasiqExpenseLogQueryVariables = Exact<{
  userId: Scalars['String'];
  expenseType: Scalars['String'];
  expenseId: Scalars['String'];
}>;


export type GetBasiqExpenseLogQuery = (
  { __typename?: 'Query' }
  & { getBasiqExpenseLog: (
    { __typename?: 'BasiqUserExpenseDataType' }
    & Pick<BasiqUserExpenseDataType, 'id' | 'userId' | 'label' | 'category' | 'frequency' | 'amount'>
    & { histroyInfo: Array<(
      { __typename?: 'BasiqUserExpenseLogDataType' }
      & Pick<BasiqUserExpenseLogDataType, 'id' | 'expense_id' | 'amount' | 'created_at'>
      & { basiqCategoryJson: (
        { __typename?: 'BasiqCategoryJsonType' }
        & Pick<BasiqCategoryJsonType, 'totalAvgAmount'>
        & { basiqCategoryData: Array<(
          { __typename?: 'BasiqCategoryDataType' }
          & Pick<BasiqCategoryDataType, 'classCode' | 'classTitle' | 'divisionTitle' | 'avgMonthly'>
          & { changeHistory: Array<(
            { __typename?: 'changeHistory' }
            & Pick<ChangeHistory, 'date' | 'amount'>
          )> }
        )> }
      ) }
    )> }
  ) }
);

export type GetBasiqUserDetailsQueryVariables = Exact<{
  userId: Scalars['String'];
}>;


export type GetBasiqUserDetailsQuery = (
  { __typename?: 'Query' }
  & { getBasiqUserDetails: (
    { __typename?: 'BasiqUserDetailType' }
    & Pick<BasiqUserDetailType, 'type' | 'email' | 'mobile'>
  ) }
);

export type GetUserBasiqExpensesQueryVariables = Exact<{
  userId: Scalars['String'];
}>;


export type GetUserBasiqExpensesQuery = (
  { __typename?: 'Query' }
  & { getUserBasiqExpenses: (
    { __typename?: 'BasiqUserExpenseData' }
    & BankUserExpenseTypeFragment
  ) }
);

export type GetBrokerAllUsersListQueryVariables = Exact<{
  filter?: Maybe<Scalars['String']>;
  sort?: Maybe<SortInput>;
  pagination?: Maybe<PaginationInput>;
}>;


export type GetBrokerAllUsersListQuery = (
  { __typename?: 'Query' }
  & { getBrokerAllUsersList: (
    { __typename?: 'UsersListResponse' }
    & Pick<UsersListResponse, 'total' | 'currentPage'>
    & { users: Array<(
      { __typename?: 'UserListSummary' }
      & Pick<UserListSummary, 'id' | 'email' | 'role' | 'personalCoachStatus'>
      & { userInfo: (
        { __typename?: 'UserInfoName' }
        & Pick<UserInfoName, 'name' | 'brokerId'>
      ) }
    )> }
  ) }
);

export type GetBrokerDetailsQueryVariables = Exact<{
  userId: Scalars['String'];
}>;


export type GetBrokerDetailsQuery = (
  { __typename?: 'Query' }
  & { user: (
    { __typename: 'User' }
    & Pick<User, 'id'>
    & MeUserFragment
  ) }
);

export type GetBrokerUsersListQueryVariables = Exact<{
  pagination?: Maybe<PaginationInput>;
}>;


export type GetBrokerUsersListQuery = (
  { __typename?: 'Query' }
  & { getBrokerUsersList: (
    { __typename?: 'UsersListResponse' }
    & Pick<UsersListResponse, 'total'>
    & { users: Array<(
      { __typename?: 'UserListSummary' }
      & Pick<UserListSummary, 'id' | 'email' | 'role'>
      & { userInfo: (
        { __typename?: 'UserInfoName' }
        & Pick<UserInfoName, 'name'>
      ) }
    )> }
  ) }
);

export type GetUserBasiqAccountsQueryVariables = Exact<{
  userId: Scalars['String'];
}>;


export type GetUserBasiqAccountsQuery = (
  { __typename?: 'Query' }
  & { getUserBasiqAccounts: (
    { __typename?: 'BasiqAccountData' }
    & BankAccountTypeFragment
  ) }
);

export type GetUserDetailsQueryVariables = Exact<{
  userId: Scalars['String'];
}>;


export type GetUserDetailsQuery = (
  { __typename?: 'Query' }
  & { getUserDetails: (
    { __typename?: 'UserDetailsWithSubscription' }
    & { user: (
      { __typename?: 'UserSummary' }
      & Pick<UserSummary, 'id' | 'email' | 'role' | 'subscriptionId' | 'personalCoachStatus' | 'personalCoachSubscriptionId' | 'name' | 'createdAt' | 'updatedAt'>
    ), subscriptionData: (
      { __typename?: 'CustomerSubscriptionList' }
      & { list: Array<(
        { __typename?: 'CustomerWithSubscription' }
        & Pick<CustomerWithSubscription, 'id'>
        & { customer: (
          { __typename?: 'CustomerObjType' }
          & Pick<CustomerObjType, 'allow_direct_debit' | 'auto_collection' | 'card_status' | 'created_at' | 'deleted' | 'excess_payments' | 'first_name' | 'id' | 'last_name' | 'net_term_days' | 'pii_cleared' | 'preferred_currency_code' | 'promotional_credits' | 'refundable_credits' | 'taxability' | 'unbilled_charges' | 'updated_at'>
        ), subscription: (
          { __typename?: 'SubscriptionObjType' }
          & Pick<SubscriptionObjType, 'activated_at' | 'billing_period' | 'billing_period_unit' | 'created_at' | 'currency_code' | 'current_term_end' | 'current_term_start' | 'customer_id' | 'id' | 'next_billing_at' | 'status' | 'updated_at'>
          & { subscription_items: Array<(
            { __typename?: 'SubscriptionItems' }
            & Pick<SubscriptionItems, 'amount' | 'free_quantity' | 'item_price_id' | 'item_type' | 'quantity' | 'unit_price'>
          )> }
        ) }
      )> }
    ) }
  ) }
);

export type MeUserQueryVariables = Exact<{
  userId: Scalars['String'];
}>;


export type MeUserQuery = (
  { __typename?: 'Query' }
  & { user: (
    { __typename: 'User' }
    & Pick<User, 'id'>
    & MeUserFragment
  ) }
);

export type ResetPasswordMutationVariables = Exact<{
  userId: Scalars['String'];
}>;


export type ResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'resetPassword'>
);

export type OtpVerifyQueryVariables = Exact<{
  userId: Scalars['String'];
  otp: Scalars['String'];
}>;


export type OtpVerifyQuery = (
  { __typename?: 'Query' }
  & { otpVerify: (
    { __typename?: 'SignUpPayload' }
    & Pick<SignUpPayload, 'token'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'role'>
    ) }
  ) }
);

export type SigninMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type SigninMutation = (
  { __typename?: 'Mutation' }
  & { signIn: (
    { __typename?: 'SignInPayload' }
    & Pick<SignInPayload, 'token'>
    & { user: (
      { __typename?: 'JwtUser' }
      & Pick<JwtUser, 'id' | 'email' | 'role'>
    ) }
  ) }
);

export type SignupMutationVariables = Exact<{
  input: SignUpInput;
}>;


export type SignupMutation = (
  { __typename?: 'Mutation' }
  & { signUp: (
    { __typename?: 'SignUpPayload' }
    & Pick<SignUpPayload, 'token'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'role'>
    ) }
  ) }
);

export type UpdateResetPasswordQueryVariables = Exact<{
  password: Scalars['String'];
  token: Scalars['String'];
}>;


export type UpdateResetPasswordQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'updateResetPassword'>
);

export type UserWithPaymentInfoLinkQueryVariables = Exact<{
  type: Scalars['String'];
  frequency: Scalars['String'];
}>;


export type UserWithPaymentInfoLinkQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'checkoutHostedPage'>
);

export type UserWithPortalSessionQueryVariables = Exact<{ [key: string]: never; }>;


export type UserWithPortalSessionQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'portalSessionObject'>
);

export type VerifyAndUpdateSubscriptionQueryVariables = Exact<{
  userId: Scalars['String'];
  subscriptionId: Scalars['String'];
  type: Scalars['String'];
}>;


export type VerifyAndUpdateSubscriptionQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'verifyAndUpdateSubscription'>
);

export type VerifyBasiqConsentQueryVariables = Exact<{
  userId: Scalars['String'];
}>;


export type VerifyBasiqConsentQuery = (
  { __typename?: 'Query' }
  & { verifyBasiqConsent: (
    { __typename?: 'BasiqUserConsentType' }
    & Pick<BasiqUserConsentType, 'type' | 'size'>
    & { data: Array<(
      { __typename?: 'BasiqUserConsentDataType' }
      & Pick<BasiqUserConsentDataType, 'type' | 'id' | 'created' | 'updated' | 'expiryDate' | 'status'>
    )> }
  ) }
);

export const BankAccountTypeFragmentDoc = gql`
    fragment bankAccountType on BasiqAccountData {
  type
  data {
    type
    id
    accountHolder
    accountNo
    availableFunds
    balance
    class {
      product
      type
    }
    connection
    currency
    institution
    lastUpdated
    name
    status
    transactionIntervals {
      from
      to
    }
  }
}
    `;
export const BankUserExpenseTypeFragmentDoc = gql`
    fragment bankUserExpenseType on BasiqUserExpenseData {
  type
  id
  fromMonth
  toMonth
  coverageDays
  bankFees {
    avgMonthly
    summary
    changeHistory {
      amount
      date
    }
  }
  cashWithdrawals {
    avgMonthly
    summary
    changeHistory {
      amount
      date
    }
  }
  externalTransfers {
    avgMonthly
    summary
    changeHistory {
      amount
      date
    }
  }
  loanInterests {
    avgMonthly
    summary
    changeHistory {
      amount
      date
    }
  }
  loanRepayments {
    avgMonthly
    summary
    changeHistory {
      amount
      date
    }
  }
  payments {
    avgMonthly
    division
    percentageTotal
    subCategory {
      category {
        expenseClass {
          classCode
          classTitle
          divisionCode
          divisionTitle
        }
      }
      changeHistory {
        amount
        date
      }
      summary
    }
  }
  links {
    self
    accounts
  }
}
    `;
export const MeUserInfoFragmentDoc = gql`
    fragment MeUserInfo on UserInfo {
  id
  name
  partnerName
  grandTotalAnnual
  accounts {
    id
    enabled
    accountType
    accountHolder
    annual
    estimateAnnualAmount
    deletable
    goalAmount
    goalYears
    label
    monthly
    percent
    percentAdditionalInfo
    setupable
    sortOrder
    createdAt
    updatedAt
    bankFeedPercent
    bankFeedAnnual
  }
  accountsAnnualTotal
  accountsAnnualUnallocated
  saving_year
  mortgage_type
  projected_savings
  loanRemainingYears
  propertyValue
  targetLvr
  useableEquity
  incomeWithoutRentalIncome
  displayAnnualRent
  dependents
  livingSituation
  relationshipStatus
  basicRentAmount
  basicRentFrequency
  mortgagePrinciple
  mortgageYearsRemaining
  mortgageAmount
  mortgageInterest
  accountOffset
  mortgageSplit {
    id
    accountOffset
    mortgageAmount
    mortgageInterest
    mortgage_type
  }
  mortgageRepaymentMonthly
  proposedMortgageRepaymentMonthly
  plannedPurchaseNoOfMonths
  allLoanClearInYears
  paydayFrequency
  extraAccountSuggestions {
    id
    label
    labelAdditionalInfo
    type
  }
  extraHolidaySuggestions {
    id
    label
    frequency
    amount
  }
  hasEveryday2
  hasFun2
  transfers {
    id
    fromBank
    toBank
    timing
    transferAmount
    transferFrequency
    account {
      id
      label
      accountType
      accountHolder
    }
  }
  setupTasks {
    prop
    complete
    label
    description
  }
  oldBankName
  newBankName
  savingsBankName
  everydayPayday1
  everydayPayday2
  personalityType1
  personalityType2
  keepVsSell
  brokerId
  brokerCalendlyLink
  brokerNumber
  brokerInfo {
    name
    brokerCalendlyLink
  }
}
    `;
export const MeUserFragmentDoc = gql`
    fragment MeUser on User {
  id
  email
  role
  acceptsMarketing
  acceptsTerms
  personalCoachStatus
  isBasiqConnected
  basiqAcceptsTerms
  basiqConsent
  isOnboardingFinished
  basiqFetchedAt
  userInfo {
    ...MeUserInfo
  }
  goals {
    id
    key
    label
    isPartnerGoal
    term
    createdAt
    updatedAt
    completed_on
    archived_on
  }
  income {
    id
    sortOrder
    label
    amount
    annual
    frequency
    grossPayEquiv
    editable
    incomeType
    hecsHelp
    accountHolder
    isBasicPay
    createdAt
    updatedAt
  }
  bills {
    id
    sortOrder
    label
    category
    frequency
    amount
    annual
    rules
    editable
    basiqCategoryJson {
      basiqCategoryData {
        classCode
        classTitle
        divisionTitle
        changeHistory {
          date
          amount
        }
        avgMonthly
      }
      totalAvgAmount
    }
  }
  everyday {
    id
    amount
    annual
    category
    label
    sortOrder
    basiqCategoryJson {
      basiqCategoryData {
        classCode
        classTitle
        divisionTitle
        changeHistory {
          date
          amount
        }
        avgMonthly
      }
      totalAvgAmount
    }
  }
  everyday2 {
    id
    amount
    annual
    category
    label
    sortOrder
  }
  fun {
    id
    amount
    frequency
    annual
    category
    label
    sortOrder
    amountAdditionalInfo
    frequencyAdditionalInfo
    basiqCategoryJson {
      basiqCategoryData {
        classCode
        classTitle
        divisionTitle
        changeHistory {
          date
          amount
        }
        avgMonthly
      }
      totalAvgAmount
    }
  }
  fun2 {
    id
    amount
    frequency
    annual
    category
    label
    sortOrder
    amountAdditionalInfo
    frequencyAdditionalInfo
  }
  holidays {
    id
    sortOrder
    label
    amount
    annual
    frequency
    createdAt
    updatedAt
    basiqCategoryJson {
      basiqCategoryData {
        classCode
        classTitle
        divisionTitle
        changeHistory {
          date
          amount
        }
        avgMonthly
      }
      totalAvgAmount
    }
  }
  investmentProperties {
    id
    name
    annual
    expensesAnnual
    depreciationsAnnual
    taxableAnnual
    annual
    vacancyRateAmount
    vacancyRateAnnual
    grossRentalAnnual
    grossRentalAmount
    rentalFrequency
    mortgageCostAnnual
    councilRatesAmount
    councilRatesFrequency
    waterRatesAmount
    waterRatesFrequency
    bodyFeesAmount
    bodyFeesFrequency
    landlordInsuranceAmount
    landlordInsuranceFrequency
    maintenanceAmount
    maintenanceFrequency
    lettingFeesAmount
    lettingFeesFrequency
    landTaxAmount
    landTaxFrequency
    otherAmount
    otherFrequency
    agentCommissionAmount
    councilRatesAnnual
    waterRatesAnnual
    bodyFeesAnnual
    landlordInsuranceAnnual
    maintenanceAnnual
    lettingFeesAnnual
    agentCommissionAnnual
    landTaxAnnual
    otherAnnual
    fnfAmount
    fnfAnnual
    buildingAmount
    buildingAnnual
    person1Proportion
    person2Proportion
    taxPaidPerson1
    taxPaidPerson2
    annualAmountPerson1
    annualAmountPerson2
    propertyValue
    targetLvr
    propertyYield
    lvr
    useableEquity
    keepVsSell
    useEquity
    isCompanyTrust
    salesProceeds
    sellingCost
    createdAt
    updatedAt
    mortgages {
      __typename
      id
      interestRate
      principle
      accountOffset
      mortgage_type
      mortgageNetPrinciple
      mortgageSplit {
        id
        mortgageAmount
        mortgageInterest
        accountOffset
        mortgage_type
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
  plannedPurchase {
    id
    state
    fbh
    purchasePrice
    purchaseLoanType
    loanAmountPercentage
    bankFees
    lenderLegals
    lenderFees
    refinanceCosts
    otherFees
    depositPaid
    savings
    gift
    shares
    equity
    personalLoan
    stampDuty
    registrationFees
    sellingCosts
    loanAmountExcludingLmi
    mortgageInsurance
    newLoan
    fundsRequired
    fundsAvailable
    netFunds
    interestRate
    interestRate2
    repaymentFrequency
    bankLoanType
    bankLoanType2
    repaymentAmount
    repaymentAmount2
    landValue
    propertyType
    totalLoanAmount
    avgLoanInterest
    totalRepaymentAmount
    salesProceeds
    plannedPurchaseType
    lmiWaiver
  }
}
    ${MeUserInfoFragmentDoc}`;
export const AddInvestmentMortgageSplitDocument = gql`
    mutation AddInvestmentMortgageSplit($propertyId: String!, $noOfSplits: Int!) {
  addInvestmentMortgageSplit(propertyId: $propertyId, noOfSplits: $noOfSplits) {
    user {
      ...MeUser
    }
  }
}
    ${MeUserFragmentDoc}`;
export type AddInvestmentMortgageSplitMutationFn = Apollo.MutationFunction<AddInvestmentMortgageSplitMutation, AddInvestmentMortgageSplitMutationVariables>;

/**
 * __useAddInvestmentMortgageSplitMutation__
 *
 * To run a mutation, you first call `useAddInvestmentMortgageSplitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddInvestmentMortgageSplitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addInvestmentMortgageSplitMutation, { data, loading, error }] = useAddInvestmentMortgageSplitMutation({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      noOfSplits: // value for 'noOfSplits'
 *   },
 * });
 */
export function useAddInvestmentMortgageSplitMutation(baseOptions?: Apollo.MutationHookOptions<AddInvestmentMortgageSplitMutation, AddInvestmentMortgageSplitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddInvestmentMortgageSplitMutation, AddInvestmentMortgageSplitMutationVariables>(AddInvestmentMortgageSplitDocument, options);
      }
export type AddInvestmentMortgageSplitMutationHookResult = ReturnType<typeof useAddInvestmentMortgageSplitMutation>;
export type AddInvestmentMortgageSplitMutationResult = Apollo.MutationResult<AddInvestmentMortgageSplitMutation>;
export type AddInvestmentMortgageSplitMutationOptions = Apollo.BaseMutationOptions<AddInvestmentMortgageSplitMutation, AddInvestmentMortgageSplitMutationVariables>;
export const AddPlannedPurchaseDocument = gql`
    mutation AddPlannedPurchase($userId: String!) {
  addPlannedPurchase(userId: $userId) {
    user {
      ...MeUser
    }
  }
}
    ${MeUserFragmentDoc}`;
export type AddPlannedPurchaseMutationFn = Apollo.MutationFunction<AddPlannedPurchaseMutation, AddPlannedPurchaseMutationVariables>;

/**
 * __useAddPlannedPurchaseMutation__
 *
 * To run a mutation, you first call `useAddPlannedPurchaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPlannedPurchaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPlannedPurchaseMutation, { data, loading, error }] = useAddPlannedPurchaseMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAddPlannedPurchaseMutation(baseOptions?: Apollo.MutationHookOptions<AddPlannedPurchaseMutation, AddPlannedPurchaseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddPlannedPurchaseMutation, AddPlannedPurchaseMutationVariables>(AddPlannedPurchaseDocument, options);
      }
export type AddPlannedPurchaseMutationHookResult = ReturnType<typeof useAddPlannedPurchaseMutation>;
export type AddPlannedPurchaseMutationResult = Apollo.MutationResult<AddPlannedPurchaseMutation>;
export type AddPlannedPurchaseMutationOptions = Apollo.BaseMutationOptions<AddPlannedPurchaseMutation, AddPlannedPurchaseMutationVariables>;
export const AddUserMortgageSplitDocument = gql`
    mutation AddUserMortgageSplit($userId: String!, $noOfSplits: Int!) {
  addUserMortgageSplit(userId: $userId, noOfSplits: $noOfSplits) {
    user {
      ...MeUser
    }
  }
}
    ${MeUserFragmentDoc}`;
export type AddUserMortgageSplitMutationFn = Apollo.MutationFunction<AddUserMortgageSplitMutation, AddUserMortgageSplitMutationVariables>;

/**
 * __useAddUserMortgageSplitMutation__
 *
 * To run a mutation, you first call `useAddUserMortgageSplitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserMortgageSplitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserMortgageSplitMutation, { data, loading, error }] = useAddUserMortgageSplitMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      noOfSplits: // value for 'noOfSplits'
 *   },
 * });
 */
export function useAddUserMortgageSplitMutation(baseOptions?: Apollo.MutationHookOptions<AddUserMortgageSplitMutation, AddUserMortgageSplitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddUserMortgageSplitMutation, AddUserMortgageSplitMutationVariables>(AddUserMortgageSplitDocument, options);
      }
export type AddUserMortgageSplitMutationHookResult = ReturnType<typeof useAddUserMortgageSplitMutation>;
export type AddUserMortgageSplitMutationResult = Apollo.MutationResult<AddUserMortgageSplitMutation>;
export type AddUserMortgageSplitMutationOptions = Apollo.BaseMutationOptions<AddUserMortgageSplitMutation, AddUserMortgageSplitMutationVariables>;
export const AddUserPlannedPurchaseDocument = gql`
    mutation AddUserPlannedPurchase($userId: String!) {
  addUserPlannedPurchase(userId: $userId) {
    user {
      ...MeUser
    }
  }
}
    ${MeUserFragmentDoc}`;
export type AddUserPlannedPurchaseMutationFn = Apollo.MutationFunction<AddUserPlannedPurchaseMutation, AddUserPlannedPurchaseMutationVariables>;

/**
 * __useAddUserPlannedPurchaseMutation__
 *
 * To run a mutation, you first call `useAddUserPlannedPurchaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserPlannedPurchaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserPlannedPurchaseMutation, { data, loading, error }] = useAddUserPlannedPurchaseMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAddUserPlannedPurchaseMutation(baseOptions?: Apollo.MutationHookOptions<AddUserPlannedPurchaseMutation, AddUserPlannedPurchaseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddUserPlannedPurchaseMutation, AddUserPlannedPurchaseMutationVariables>(AddUserPlannedPurchaseDocument, options);
      }
export type AddUserPlannedPurchaseMutationHookResult = ReturnType<typeof useAddUserPlannedPurchaseMutation>;
export type AddUserPlannedPurchaseMutationResult = Apollo.MutationResult<AddUserPlannedPurchaseMutation>;
export type AddUserPlannedPurchaseMutationOptions = Apollo.BaseMutationOptions<AddUserPlannedPurchaseMutation, AddUserPlannedPurchaseMutationVariables>;
export const AnnualAmountEstimateEditDocument = gql`
    mutation AnnualAmountEstimateEdit($accountId: String!, $newAmount: Int) {
  editEstimateAnnualAmount(
    accountId: $accountId
    estimateAnnualAmount: $newAmount
  ) {
    user {
      ...MeUser
    }
  }
}
    ${MeUserFragmentDoc}`;
export type AnnualAmountEstimateEditMutationFn = Apollo.MutationFunction<AnnualAmountEstimateEditMutation, AnnualAmountEstimateEditMutationVariables>;

/**
 * __useAnnualAmountEstimateEditMutation__
 *
 * To run a mutation, you first call `useAnnualAmountEstimateEditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAnnualAmountEstimateEditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [annualAmountEstimateEditMutation, { data, loading, error }] = useAnnualAmountEstimateEditMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      newAmount: // value for 'newAmount'
 *   },
 * });
 */
export function useAnnualAmountEstimateEditMutation(baseOptions?: Apollo.MutationHookOptions<AnnualAmountEstimateEditMutation, AnnualAmountEstimateEditMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AnnualAmountEstimateEditMutation, AnnualAmountEstimateEditMutationVariables>(AnnualAmountEstimateEditDocument, options);
      }
export type AnnualAmountEstimateEditMutationHookResult = ReturnType<typeof useAnnualAmountEstimateEditMutation>;
export type AnnualAmountEstimateEditMutationResult = Apollo.MutationResult<AnnualAmountEstimateEditMutation>;
export type AnnualAmountEstimateEditMutationOptions = Apollo.BaseMutationOptions<AnnualAmountEstimateEditMutation, AnnualAmountEstimateEditMutationVariables>;
export const BasiqAcceptsTermsDocument = gql`
    mutation BasiqAcceptsTerms($userId: String!) {
  basiqAcceptsTerms(userId: $userId) {
    user {
      ...MeUser
    }
  }
}
    ${MeUserFragmentDoc}`;
export type BasiqAcceptsTermsMutationFn = Apollo.MutationFunction<BasiqAcceptsTermsMutation, BasiqAcceptsTermsMutationVariables>;

/**
 * __useBasiqAcceptsTermsMutation__
 *
 * To run a mutation, you first call `useBasiqAcceptsTermsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBasiqAcceptsTermsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [basiqAcceptsTermsMutation, { data, loading, error }] = useBasiqAcceptsTermsMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useBasiqAcceptsTermsMutation(baseOptions?: Apollo.MutationHookOptions<BasiqAcceptsTermsMutation, BasiqAcceptsTermsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BasiqAcceptsTermsMutation, BasiqAcceptsTermsMutationVariables>(BasiqAcceptsTermsDocument, options);
      }
export type BasiqAcceptsTermsMutationHookResult = ReturnType<typeof useBasiqAcceptsTermsMutation>;
export type BasiqAcceptsTermsMutationResult = Apollo.MutationResult<BasiqAcceptsTermsMutation>;
export type BasiqAcceptsTermsMutationOptions = Apollo.BaseMutationOptions<BasiqAcceptsTermsMutation, BasiqAcceptsTermsMutationVariables>;
export const BillAddDocument = gql`
    mutation BillAdd($userId: String!, $input: CreateBillInput!) {
  addUserBill(userId: $userId, input: $input) {
    user {
      ...MeUser
    }
  }
}
    ${MeUserFragmentDoc}`;
export type BillAddMutationFn = Apollo.MutationFunction<BillAddMutation, BillAddMutationVariables>;

/**
 * __useBillAddMutation__
 *
 * To run a mutation, you first call `useBillAddMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBillAddMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [billAddMutation, { data, loading, error }] = useBillAddMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBillAddMutation(baseOptions?: Apollo.MutationHookOptions<BillAddMutation, BillAddMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BillAddMutation, BillAddMutationVariables>(BillAddDocument, options);
      }
export type BillAddMutationHookResult = ReturnType<typeof useBillAddMutation>;
export type BillAddMutationResult = Apollo.MutationResult<BillAddMutation>;
export type BillAddMutationOptions = Apollo.BaseMutationOptions<BillAddMutation, BillAddMutationVariables>;
export const BillDeleteDocument = gql`
    mutation BillDelete($billId: String!) {
  deleteUserBill(billId: $billId) {
    user {
      ...MeUser
    }
  }
}
    ${MeUserFragmentDoc}`;
export type BillDeleteMutationFn = Apollo.MutationFunction<BillDeleteMutation, BillDeleteMutationVariables>;

/**
 * __useBillDeleteMutation__
 *
 * To run a mutation, you first call `useBillDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBillDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [billDeleteMutation, { data, loading, error }] = useBillDeleteMutation({
 *   variables: {
 *      billId: // value for 'billId'
 *   },
 * });
 */
export function useBillDeleteMutation(baseOptions?: Apollo.MutationHookOptions<BillDeleteMutation, BillDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BillDeleteMutation, BillDeleteMutationVariables>(BillDeleteDocument, options);
      }
export type BillDeleteMutationHookResult = ReturnType<typeof useBillDeleteMutation>;
export type BillDeleteMutationResult = Apollo.MutationResult<BillDeleteMutation>;
export type BillDeleteMutationOptions = Apollo.BaseMutationOptions<BillDeleteMutation, BillDeleteMutationVariables>;
export const BillEditDocument = gql`
    mutation BillEdit($billId: String!, $input: EditBillInput!) {
  editUserBill(billId: $billId, input: $input) {
    user {
      ...MeUser
    }
  }
}
    ${MeUserFragmentDoc}`;
export type BillEditMutationFn = Apollo.MutationFunction<BillEditMutation, BillEditMutationVariables>;

/**
 * __useBillEditMutation__
 *
 * To run a mutation, you first call `useBillEditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBillEditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [billEditMutation, { data, loading, error }] = useBillEditMutation({
 *   variables: {
 *      billId: // value for 'billId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBillEditMutation(baseOptions?: Apollo.MutationHookOptions<BillEditMutation, BillEditMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BillEditMutation, BillEditMutationVariables>(BillEditDocument, options);
      }
export type BillEditMutationHookResult = ReturnType<typeof useBillEditMutation>;
export type BillEditMutationResult = Apollo.MutationResult<BillEditMutation>;
export type BillEditMutationOptions = Apollo.BaseMutationOptions<BillEditMutation, BillEditMutationVariables>;
export const ClaimHostedPageDocument = gql`
    mutation ClaimHostedPage($userId: String!, $id: String!, $type: String!) {
  claimHostedPage(userId: $userId, id: $id, type: $type) {
    ...MeUser
  }
}
    ${MeUserFragmentDoc}`;
export type ClaimHostedPageMutationFn = Apollo.MutationFunction<ClaimHostedPageMutation, ClaimHostedPageMutationVariables>;

/**
 * __useClaimHostedPageMutation__
 *
 * To run a mutation, you first call `useClaimHostedPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClaimHostedPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [claimHostedPageMutation, { data, loading, error }] = useClaimHostedPageMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      id: // value for 'id'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useClaimHostedPageMutation(baseOptions?: Apollo.MutationHookOptions<ClaimHostedPageMutation, ClaimHostedPageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ClaimHostedPageMutation, ClaimHostedPageMutationVariables>(ClaimHostedPageDocument, options);
      }
export type ClaimHostedPageMutationHookResult = ReturnType<typeof useClaimHostedPageMutation>;
export type ClaimHostedPageMutationResult = Apollo.MutationResult<ClaimHostedPageMutation>;
export type ClaimHostedPageMutationOptions = Apollo.BaseMutationOptions<ClaimHostedPageMutation, ClaimHostedPageMutationVariables>;
export const CreateUserBasiqAccountDocument = gql`
    mutation CreateUserBasiqAccount($userId: String!, $email: String!, $mobile: String!) {
  createBasiqUser(userId: $userId, email: $email, mobile: $mobile) {
    user {
      ...MeUser
    }
  }
}
    ${MeUserFragmentDoc}`;
export type CreateUserBasiqAccountMutationFn = Apollo.MutationFunction<CreateUserBasiqAccountMutation, CreateUserBasiqAccountMutationVariables>;

/**
 * __useCreateUserBasiqAccountMutation__
 *
 * To run a mutation, you first call `useCreateUserBasiqAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserBasiqAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserBasiqAccountMutation, { data, loading, error }] = useCreateUserBasiqAccountMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      email: // value for 'email'
 *      mobile: // value for 'mobile'
 *   },
 * });
 */
export function useCreateUserBasiqAccountMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserBasiqAccountMutation, CreateUserBasiqAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserBasiqAccountMutation, CreateUserBasiqAccountMutationVariables>(CreateUserBasiqAccountDocument, options);
      }
export type CreateUserBasiqAccountMutationHookResult = ReturnType<typeof useCreateUserBasiqAccountMutation>;
export type CreateUserBasiqAccountMutationResult = Apollo.MutationResult<CreateUserBasiqAccountMutation>;
export type CreateUserBasiqAccountMutationOptions = Apollo.BaseMutationOptions<CreateUserBasiqAccountMutation, CreateUserBasiqAccountMutationVariables>;
export const EditDefaultValueDocument = gql`
    mutation EditDefaultValue($userId: String!, $input: DefaultValuesInput!) {
  editDefaultValue(userId: $userId, input: $input) {
    user {
      ...MeUser
    }
  }
}
    ${MeUserFragmentDoc}`;
export type EditDefaultValueMutationFn = Apollo.MutationFunction<EditDefaultValueMutation, EditDefaultValueMutationVariables>;

/**
 * __useEditDefaultValueMutation__
 *
 * To run a mutation, you first call `useEditDefaultValueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditDefaultValueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editDefaultValueMutation, { data, loading, error }] = useEditDefaultValueMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditDefaultValueMutation(baseOptions?: Apollo.MutationHookOptions<EditDefaultValueMutation, EditDefaultValueMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditDefaultValueMutation, EditDefaultValueMutationVariables>(EditDefaultValueDocument, options);
      }
export type EditDefaultValueMutationHookResult = ReturnType<typeof useEditDefaultValueMutation>;
export type EditDefaultValueMutationResult = Apollo.MutationResult<EditDefaultValueMutation>;
export type EditDefaultValueMutationOptions = Apollo.BaseMutationOptions<EditDefaultValueMutation, EditDefaultValueMutationVariables>;
export const DefaultValueListDocument = gql`
    query DefaultValueList($userId: String!) {
  defaultValueList(userId: $userId) {
    id
    type
    value
  }
}
    `;

/**
 * __useDefaultValueListQuery__
 *
 * To run a query within a React component, call `useDefaultValueListQuery` and pass it any options that fit your needs.
 * When your component renders, `useDefaultValueListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDefaultValueListQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useDefaultValueListQuery(baseOptions: Apollo.QueryHookOptions<DefaultValueListQuery, DefaultValueListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DefaultValueListQuery, DefaultValueListQueryVariables>(DefaultValueListDocument, options);
      }
export function useDefaultValueListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DefaultValueListQuery, DefaultValueListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DefaultValueListQuery, DefaultValueListQueryVariables>(DefaultValueListDocument, options);
        }
export type DefaultValueListQueryHookResult = ReturnType<typeof useDefaultValueListQuery>;
export type DefaultValueListLazyQueryHookResult = ReturnType<typeof useDefaultValueListLazyQuery>;
export type DefaultValueListQueryResult = Apollo.QueryResult<DefaultValueListQuery, DefaultValueListQueryVariables>;
export const DeleteInvestmentMortgageSplitDocument = gql`
    mutation deleteInvestmentMortgageSplit($propertyId: String!, $mortgageSplitId: String!) {
  deleteInvestmentMortgageSplit(
    propertyId: $propertyId
    mortgageSplitId: $mortgageSplitId
  ) {
    user {
      ...MeUser
    }
  }
}
    ${MeUserFragmentDoc}`;
export type DeleteInvestmentMortgageSplitMutationFn = Apollo.MutationFunction<DeleteInvestmentMortgageSplitMutation, DeleteInvestmentMortgageSplitMutationVariables>;

/**
 * __useDeleteInvestmentMortgageSplitMutation__
 *
 * To run a mutation, you first call `useDeleteInvestmentMortgageSplitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInvestmentMortgageSplitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInvestmentMortgageSplitMutation, { data, loading, error }] = useDeleteInvestmentMortgageSplitMutation({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      mortgageSplitId: // value for 'mortgageSplitId'
 *   },
 * });
 */
export function useDeleteInvestmentMortgageSplitMutation(baseOptions?: Apollo.MutationHookOptions<DeleteInvestmentMortgageSplitMutation, DeleteInvestmentMortgageSplitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteInvestmentMortgageSplitMutation, DeleteInvestmentMortgageSplitMutationVariables>(DeleteInvestmentMortgageSplitDocument, options);
      }
export type DeleteInvestmentMortgageSplitMutationHookResult = ReturnType<typeof useDeleteInvestmentMortgageSplitMutation>;
export type DeleteInvestmentMortgageSplitMutationResult = Apollo.MutationResult<DeleteInvestmentMortgageSplitMutation>;
export type DeleteInvestmentMortgageSplitMutationOptions = Apollo.BaseMutationOptions<DeleteInvestmentMortgageSplitMutation, DeleteInvestmentMortgageSplitMutationVariables>;
export const DeleteUserMortgageSplitDocument = gql`
    mutation DeleteUserMortgageSplit($userId: String!, $mortgageSplitId: String!) {
  deleteUserMortgageSplit(userId: $userId, mortgageSplitId: $mortgageSplitId) {
    user {
      ...MeUser
    }
  }
}
    ${MeUserFragmentDoc}`;
export type DeleteUserMortgageSplitMutationFn = Apollo.MutationFunction<DeleteUserMortgageSplitMutation, DeleteUserMortgageSplitMutationVariables>;

/**
 * __useDeleteUserMortgageSplitMutation__
 *
 * To run a mutation, you first call `useDeleteUserMortgageSplitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMortgageSplitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMortgageSplitMutation, { data, loading, error }] = useDeleteUserMortgageSplitMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      mortgageSplitId: // value for 'mortgageSplitId'
 *   },
 * });
 */
export function useDeleteUserMortgageSplitMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMortgageSplitMutation, DeleteUserMortgageSplitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMortgageSplitMutation, DeleteUserMortgageSplitMutationVariables>(DeleteUserMortgageSplitDocument, options);
      }
export type DeleteUserMortgageSplitMutationHookResult = ReturnType<typeof useDeleteUserMortgageSplitMutation>;
export type DeleteUserMortgageSplitMutationResult = Apollo.MutationResult<DeleteUserMortgageSplitMutation>;
export type DeleteUserMortgageSplitMutationOptions = Apollo.BaseMutationOptions<DeleteUserMortgageSplitMutation, DeleteUserMortgageSplitMutationVariables>;
export const DisableAccountDocument = gql`
    mutation DisableAccount($userId: String!, $accountId: String!) {
  disableAccount(userId: $userId, accountId: $accountId) {
    user {
      ...MeUser
    }
  }
}
    ${MeUserFragmentDoc}`;
export type DisableAccountMutationFn = Apollo.MutationFunction<DisableAccountMutation, DisableAccountMutationVariables>;

/**
 * __useDisableAccountMutation__
 *
 * To run a mutation, you first call `useDisableAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisableAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disableAccountMutation, { data, loading, error }] = useDisableAccountMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useDisableAccountMutation(baseOptions?: Apollo.MutationHookOptions<DisableAccountMutation, DisableAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DisableAccountMutation, DisableAccountMutationVariables>(DisableAccountDocument, options);
      }
export type DisableAccountMutationHookResult = ReturnType<typeof useDisableAccountMutation>;
export type DisableAccountMutationResult = Apollo.MutationResult<DisableAccountMutation>;
export type DisableAccountMutationOptions = Apollo.BaseMutationOptions<DisableAccountMutation, DisableAccountMutationVariables>;
export const EditInvestmentMortgageSplitDocument = gql`
    mutation EditInvestmentMortgageSplit($propertyId: String!, $mortgageSplitId: String!, $input: EditMortgageSplitInput!) {
  editInvestmentMortgageSplit(
    propertyId: $propertyId
    mortgageSplitId: $mortgageSplitId
    input: $input
  ) {
    user {
      ...MeUser
    }
  }
}
    ${MeUserFragmentDoc}`;
export type EditInvestmentMortgageSplitMutationFn = Apollo.MutationFunction<EditInvestmentMortgageSplitMutation, EditInvestmentMortgageSplitMutationVariables>;

/**
 * __useEditInvestmentMortgageSplitMutation__
 *
 * To run a mutation, you first call `useEditInvestmentMortgageSplitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditInvestmentMortgageSplitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editInvestmentMortgageSplitMutation, { data, loading, error }] = useEditInvestmentMortgageSplitMutation({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      mortgageSplitId: // value for 'mortgageSplitId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditInvestmentMortgageSplitMutation(baseOptions?: Apollo.MutationHookOptions<EditInvestmentMortgageSplitMutation, EditInvestmentMortgageSplitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditInvestmentMortgageSplitMutation, EditInvestmentMortgageSplitMutationVariables>(EditInvestmentMortgageSplitDocument, options);
      }
export type EditInvestmentMortgageSplitMutationHookResult = ReturnType<typeof useEditInvestmentMortgageSplitMutation>;
export type EditInvestmentMortgageSplitMutationResult = Apollo.MutationResult<EditInvestmentMortgageSplitMutation>;
export type EditInvestmentMortgageSplitMutationOptions = Apollo.BaseMutationOptions<EditInvestmentMortgageSplitMutation, EditInvestmentMortgageSplitMutationVariables>;
export const EditPlannedPurchaseDocument = gql`
    mutation EditPlannedPurchase($plannedPurchaseId: String!, $input: PlannedPurchaseEditInput!) {
  editPlannedPurchase(plannedPurchaseId: $plannedPurchaseId, input: $input) {
    user {
      ...MeUser
    }
  }
}
    ${MeUserFragmentDoc}`;
export type EditPlannedPurchaseMutationFn = Apollo.MutationFunction<EditPlannedPurchaseMutation, EditPlannedPurchaseMutationVariables>;

/**
 * __useEditPlannedPurchaseMutation__
 *
 * To run a mutation, you first call `useEditPlannedPurchaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditPlannedPurchaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editPlannedPurchaseMutation, { data, loading, error }] = useEditPlannedPurchaseMutation({
 *   variables: {
 *      plannedPurchaseId: // value for 'plannedPurchaseId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditPlannedPurchaseMutation(baseOptions?: Apollo.MutationHookOptions<EditPlannedPurchaseMutation, EditPlannedPurchaseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditPlannedPurchaseMutation, EditPlannedPurchaseMutationVariables>(EditPlannedPurchaseDocument, options);
      }
export type EditPlannedPurchaseMutationHookResult = ReturnType<typeof useEditPlannedPurchaseMutation>;
export type EditPlannedPurchaseMutationResult = Apollo.MutationResult<EditPlannedPurchaseMutation>;
export type EditPlannedPurchaseMutationOptions = Apollo.BaseMutationOptions<EditPlannedPurchaseMutation, EditPlannedPurchaseMutationVariables>;
export const EditUserMortgageSplitDocument = gql`
    mutation EditUserMortgageSplit($userId: String!, $mortgageSplitId: String!, $input: EditMortgageSplitInput!) {
  editUserMortgageSplit(
    userId: $userId
    mortgageSplitId: $mortgageSplitId
    input: $input
  ) {
    user {
      ...MeUser
    }
  }
}
    ${MeUserFragmentDoc}`;
export type EditUserMortgageSplitMutationFn = Apollo.MutationFunction<EditUserMortgageSplitMutation, EditUserMortgageSplitMutationVariables>;

/**
 * __useEditUserMortgageSplitMutation__
 *
 * To run a mutation, you first call `useEditUserMortgageSplitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditUserMortgageSplitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editUserMortgageSplitMutation, { data, loading, error }] = useEditUserMortgageSplitMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      mortgageSplitId: // value for 'mortgageSplitId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditUserMortgageSplitMutation(baseOptions?: Apollo.MutationHookOptions<EditUserMortgageSplitMutation, EditUserMortgageSplitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditUserMortgageSplitMutation, EditUserMortgageSplitMutationVariables>(EditUserMortgageSplitDocument, options);
      }
export type EditUserMortgageSplitMutationHookResult = ReturnType<typeof useEditUserMortgageSplitMutation>;
export type EditUserMortgageSplitMutationResult = Apollo.MutationResult<EditUserMortgageSplitMutation>;
export type EditUserMortgageSplitMutationOptions = Apollo.BaseMutationOptions<EditUserMortgageSplitMutation, EditUserMortgageSplitMutationVariables>;
export const EditUserOnboardingFinishedDocument = gql`
    mutation EditUserOnboardingFinished($userId: String!, $isOnboardingFinished: Boolean!) {
  editUserOnboardingFinished(
    userId: $userId
    isOnboardingFinished: $isOnboardingFinished
  ) {
    user {
      ...MeUser
    }
  }
}
    ${MeUserFragmentDoc}`;
export type EditUserOnboardingFinishedMutationFn = Apollo.MutationFunction<EditUserOnboardingFinishedMutation, EditUserOnboardingFinishedMutationVariables>;

/**
 * __useEditUserOnboardingFinishedMutation__
 *
 * To run a mutation, you first call `useEditUserOnboardingFinishedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditUserOnboardingFinishedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editUserOnboardingFinishedMutation, { data, loading, error }] = useEditUserOnboardingFinishedMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      isOnboardingFinished: // value for 'isOnboardingFinished'
 *   },
 * });
 */
export function useEditUserOnboardingFinishedMutation(baseOptions?: Apollo.MutationHookOptions<EditUserOnboardingFinishedMutation, EditUserOnboardingFinishedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditUserOnboardingFinishedMutation, EditUserOnboardingFinishedMutationVariables>(EditUserOnboardingFinishedDocument, options);
      }
export type EditUserOnboardingFinishedMutationHookResult = ReturnType<typeof useEditUserOnboardingFinishedMutation>;
export type EditUserOnboardingFinishedMutationResult = Apollo.MutationResult<EditUserOnboardingFinishedMutation>;
export type EditUserOnboardingFinishedMutationOptions = Apollo.BaseMutationOptions<EditUserOnboardingFinishedMutation, EditUserOnboardingFinishedMutationVariables>;
export const EnableAccountDocument = gql`
    mutation EnableAccount($userId: String!, $accountId: String!) {
  enableAccount(userId: $userId, accountId: $accountId) {
    user {
      ...MeUser
    }
  }
}
    ${MeUserFragmentDoc}`;
export type EnableAccountMutationFn = Apollo.MutationFunction<EnableAccountMutation, EnableAccountMutationVariables>;

/**
 * __useEnableAccountMutation__
 *
 * To run a mutation, you first call `useEnableAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnableAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enableAccountMutation, { data, loading, error }] = useEnableAccountMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useEnableAccountMutation(baseOptions?: Apollo.MutationHookOptions<EnableAccountMutation, EnableAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EnableAccountMutation, EnableAccountMutationVariables>(EnableAccountDocument, options);
      }
export type EnableAccountMutationHookResult = ReturnType<typeof useEnableAccountMutation>;
export type EnableAccountMutationResult = Apollo.MutationResult<EnableAccountMutation>;
export type EnableAccountMutationOptions = Apollo.BaseMutationOptions<EnableAccountMutation, EnableAccountMutationVariables>;
export const EverydayAddDocument = gql`
    mutation EverydayAdd($userId: String!, $accountHolder: AccountHolder!, $input: CreateEverydayExpenseInput!) {
  addUserEverydayExpense(
    userId: $userId
    accountHolder: $accountHolder
    input: $input
  ) {
    user {
      ...MeUser
    }
  }
}
    ${MeUserFragmentDoc}`;
export type EverydayAddMutationFn = Apollo.MutationFunction<EverydayAddMutation, EverydayAddMutationVariables>;

/**
 * __useEverydayAddMutation__
 *
 * To run a mutation, you first call `useEverydayAddMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEverydayAddMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [everydayAddMutation, { data, loading, error }] = useEverydayAddMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      accountHolder: // value for 'accountHolder'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEverydayAddMutation(baseOptions?: Apollo.MutationHookOptions<EverydayAddMutation, EverydayAddMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EverydayAddMutation, EverydayAddMutationVariables>(EverydayAddDocument, options);
      }
export type EverydayAddMutationHookResult = ReturnType<typeof useEverydayAddMutation>;
export type EverydayAddMutationResult = Apollo.MutationResult<EverydayAddMutation>;
export type EverydayAddMutationOptions = Apollo.BaseMutationOptions<EverydayAddMutation, EverydayAddMutationVariables>;
export const EverydayDeleteDocument = gql`
    mutation EverydayDelete($everydayId: String!) {
  deleteUserEveryday(everydayId: $everydayId) {
    user {
      ...MeUser
    }
  }
}
    ${MeUserFragmentDoc}`;
export type EverydayDeleteMutationFn = Apollo.MutationFunction<EverydayDeleteMutation, EverydayDeleteMutationVariables>;

/**
 * __useEverydayDeleteMutation__
 *
 * To run a mutation, you first call `useEverydayDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEverydayDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [everydayDeleteMutation, { data, loading, error }] = useEverydayDeleteMutation({
 *   variables: {
 *      everydayId: // value for 'everydayId'
 *   },
 * });
 */
export function useEverydayDeleteMutation(baseOptions?: Apollo.MutationHookOptions<EverydayDeleteMutation, EverydayDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EverydayDeleteMutation, EverydayDeleteMutationVariables>(EverydayDeleteDocument, options);
      }
export type EverydayDeleteMutationHookResult = ReturnType<typeof useEverydayDeleteMutation>;
export type EverydayDeleteMutationResult = Apollo.MutationResult<EverydayDeleteMutation>;
export type EverydayDeleteMutationOptions = Apollo.BaseMutationOptions<EverydayDeleteMutation, EverydayDeleteMutationVariables>;
export const EverydayEditDocument = gql`
    mutation EverydayEdit($everydayId: String!, $input: EditEverydayExpenseInput!) {
  editUserEveryday(everydayId: $everydayId, input: $input) {
    user {
      ...MeUser
    }
  }
}
    ${MeUserFragmentDoc}`;
export type EverydayEditMutationFn = Apollo.MutationFunction<EverydayEditMutation, EverydayEditMutationVariables>;

/**
 * __useEverydayEditMutation__
 *
 * To run a mutation, you first call `useEverydayEditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEverydayEditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [everydayEditMutation, { data, loading, error }] = useEverydayEditMutation({
 *   variables: {
 *      everydayId: // value for 'everydayId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEverydayEditMutation(baseOptions?: Apollo.MutationHookOptions<EverydayEditMutation, EverydayEditMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EverydayEditMutation, EverydayEditMutationVariables>(EverydayEditDocument, options);
      }
export type EverydayEditMutationHookResult = ReturnType<typeof useEverydayEditMutation>;
export type EverydayEditMutationResult = Apollo.MutationResult<EverydayEditMutation>;
export type EverydayEditMutationOptions = Apollo.BaseMutationOptions<EverydayEditMutation, EverydayEditMutationVariables>;
export const FunAddDocument = gql`
    mutation FunAdd($userId: String!, $accountHolder: AccountHolder!, $input: CreateFunExpenseInput!) {
  addUserFunExpense(userId: $userId, accountHolder: $accountHolder, input: $input) {
    user {
      ...MeUser
    }
  }
}
    ${MeUserFragmentDoc}`;
export type FunAddMutationFn = Apollo.MutationFunction<FunAddMutation, FunAddMutationVariables>;

/**
 * __useFunAddMutation__
 *
 * To run a mutation, you first call `useFunAddMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFunAddMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [funAddMutation, { data, loading, error }] = useFunAddMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      accountHolder: // value for 'accountHolder'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFunAddMutation(baseOptions?: Apollo.MutationHookOptions<FunAddMutation, FunAddMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FunAddMutation, FunAddMutationVariables>(FunAddDocument, options);
      }
export type FunAddMutationHookResult = ReturnType<typeof useFunAddMutation>;
export type FunAddMutationResult = Apollo.MutationResult<FunAddMutation>;
export type FunAddMutationOptions = Apollo.BaseMutationOptions<FunAddMutation, FunAddMutationVariables>;
export const FunDeleteDocument = gql`
    mutation FunDelete($funId: String!) {
  deleteUserFun(funId: $funId) {
    user {
      ...MeUser
    }
  }
}
    ${MeUserFragmentDoc}`;
export type FunDeleteMutationFn = Apollo.MutationFunction<FunDeleteMutation, FunDeleteMutationVariables>;

/**
 * __useFunDeleteMutation__
 *
 * To run a mutation, you first call `useFunDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFunDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [funDeleteMutation, { data, loading, error }] = useFunDeleteMutation({
 *   variables: {
 *      funId: // value for 'funId'
 *   },
 * });
 */
export function useFunDeleteMutation(baseOptions?: Apollo.MutationHookOptions<FunDeleteMutation, FunDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FunDeleteMutation, FunDeleteMutationVariables>(FunDeleteDocument, options);
      }
export type FunDeleteMutationHookResult = ReturnType<typeof useFunDeleteMutation>;
export type FunDeleteMutationResult = Apollo.MutationResult<FunDeleteMutation>;
export type FunDeleteMutationOptions = Apollo.BaseMutationOptions<FunDeleteMutation, FunDeleteMutationVariables>;
export const FunEditDocument = gql`
    mutation FunEdit($funId: String!, $input: EditFunExpenseInput!) {
  editUserFun(funId: $funId, input: $input) {
    user {
      ...MeUser
    }
  }
}
    ${MeUserFragmentDoc}`;
export type FunEditMutationFn = Apollo.MutationFunction<FunEditMutation, FunEditMutationVariables>;

/**
 * __useFunEditMutation__
 *
 * To run a mutation, you first call `useFunEditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFunEditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [funEditMutation, { data, loading, error }] = useFunEditMutation({
 *   variables: {
 *      funId: // value for 'funId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFunEditMutation(baseOptions?: Apollo.MutationHookOptions<FunEditMutation, FunEditMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FunEditMutation, FunEditMutationVariables>(FunEditDocument, options);
      }
export type FunEditMutationHookResult = ReturnType<typeof useFunEditMutation>;
export type FunEditMutationResult = Apollo.MutationResult<FunEditMutation>;
export type FunEditMutationOptions = Apollo.BaseMutationOptions<FunEditMutation, FunEditMutationVariables>;
export const GoalAccountEditDocument = gql`
    mutation GoalAccountEdit($accountId: String!, $goalYears: Int!, $goalAmount: Int!) {
  editGoalAccount(
    accountId: $accountId
    goalYears: $goalYears
    goalAmount: $goalAmount
  ) {
    user {
      ...MeUser
    }
  }
}
    ${MeUserFragmentDoc}`;
export type GoalAccountEditMutationFn = Apollo.MutationFunction<GoalAccountEditMutation, GoalAccountEditMutationVariables>;

/**
 * __useGoalAccountEditMutation__
 *
 * To run a mutation, you first call `useGoalAccountEditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGoalAccountEditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [goalAccountEditMutation, { data, loading, error }] = useGoalAccountEditMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      goalYears: // value for 'goalYears'
 *      goalAmount: // value for 'goalAmount'
 *   },
 * });
 */
export function useGoalAccountEditMutation(baseOptions?: Apollo.MutationHookOptions<GoalAccountEditMutation, GoalAccountEditMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GoalAccountEditMutation, GoalAccountEditMutationVariables>(GoalAccountEditDocument, options);
      }
export type GoalAccountEditMutationHookResult = ReturnType<typeof useGoalAccountEditMutation>;
export type GoalAccountEditMutationResult = Apollo.MutationResult<GoalAccountEditMutation>;
export type GoalAccountEditMutationOptions = Apollo.BaseMutationOptions<GoalAccountEditMutation, GoalAccountEditMutationVariables>;
export const GoalAddDocument = gql`
    mutation GoalAdd($userId: String!, $input: GoalInput!) {
  addUserGoal(userId: $userId, input: $input) {
    user {
      ...MeUser
    }
  }
}
    ${MeUserFragmentDoc}`;
export type GoalAddMutationFn = Apollo.MutationFunction<GoalAddMutation, GoalAddMutationVariables>;

/**
 * __useGoalAddMutation__
 *
 * To run a mutation, you first call `useGoalAddMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGoalAddMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [goalAddMutation, { data, loading, error }] = useGoalAddMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGoalAddMutation(baseOptions?: Apollo.MutationHookOptions<GoalAddMutation, GoalAddMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GoalAddMutation, GoalAddMutationVariables>(GoalAddDocument, options);
      }
export type GoalAddMutationHookResult = ReturnType<typeof useGoalAddMutation>;
export type GoalAddMutationResult = Apollo.MutationResult<GoalAddMutation>;
export type GoalAddMutationOptions = Apollo.BaseMutationOptions<GoalAddMutation, GoalAddMutationVariables>;
export const GoalArchivedDocument = gql`
    mutation GoalArchived($goalId: String!) {
  goalArchived(goalId: $goalId) {
    user {
      ...MeUser
    }
  }
}
    ${MeUserFragmentDoc}`;
export type GoalArchivedMutationFn = Apollo.MutationFunction<GoalArchivedMutation, GoalArchivedMutationVariables>;

/**
 * __useGoalArchivedMutation__
 *
 * To run a mutation, you first call `useGoalArchivedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGoalArchivedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [goalArchivedMutation, { data, loading, error }] = useGoalArchivedMutation({
 *   variables: {
 *      goalId: // value for 'goalId'
 *   },
 * });
 */
export function useGoalArchivedMutation(baseOptions?: Apollo.MutationHookOptions<GoalArchivedMutation, GoalArchivedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GoalArchivedMutation, GoalArchivedMutationVariables>(GoalArchivedDocument, options);
      }
export type GoalArchivedMutationHookResult = ReturnType<typeof useGoalArchivedMutation>;
export type GoalArchivedMutationResult = Apollo.MutationResult<GoalArchivedMutation>;
export type GoalArchivedMutationOptions = Apollo.BaseMutationOptions<GoalArchivedMutation, GoalArchivedMutationVariables>;
export const GoalCompletedDocument = gql`
    mutation GoalCompleted($goalId: String!) {
  goalCompleted(goalId: $goalId) {
    user {
      ...MeUser
    }
  }
}
    ${MeUserFragmentDoc}`;
export type GoalCompletedMutationFn = Apollo.MutationFunction<GoalCompletedMutation, GoalCompletedMutationVariables>;

/**
 * __useGoalCompletedMutation__
 *
 * To run a mutation, you first call `useGoalCompletedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGoalCompletedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [goalCompletedMutation, { data, loading, error }] = useGoalCompletedMutation({
 *   variables: {
 *      goalId: // value for 'goalId'
 *   },
 * });
 */
export function useGoalCompletedMutation(baseOptions?: Apollo.MutationHookOptions<GoalCompletedMutation, GoalCompletedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GoalCompletedMutation, GoalCompletedMutationVariables>(GoalCompletedDocument, options);
      }
export type GoalCompletedMutationHookResult = ReturnType<typeof useGoalCompletedMutation>;
export type GoalCompletedMutationResult = Apollo.MutationResult<GoalCompletedMutation>;
export type GoalCompletedMutationOptions = Apollo.BaseMutationOptions<GoalCompletedMutation, GoalCompletedMutationVariables>;
export const DeleteUserGoalDocument = gql`
    mutation DeleteUserGoal($goalId: String!) {
  deleteUserGoal(goalId: $goalId) {
    user {
      ...MeUser
    }
  }
}
    ${MeUserFragmentDoc}`;
export type DeleteUserGoalMutationFn = Apollo.MutationFunction<DeleteUserGoalMutation, DeleteUserGoalMutationVariables>;

/**
 * __useDeleteUserGoalMutation__
 *
 * To run a mutation, you first call `useDeleteUserGoalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserGoalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserGoalMutation, { data, loading, error }] = useDeleteUserGoalMutation({
 *   variables: {
 *      goalId: // value for 'goalId'
 *   },
 * });
 */
export function useDeleteUserGoalMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserGoalMutation, DeleteUserGoalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserGoalMutation, DeleteUserGoalMutationVariables>(DeleteUserGoalDocument, options);
      }
export type DeleteUserGoalMutationHookResult = ReturnType<typeof useDeleteUserGoalMutation>;
export type DeleteUserGoalMutationResult = Apollo.MutationResult<DeleteUserGoalMutation>;
export type DeleteUserGoalMutationOptions = Apollo.BaseMutationOptions<DeleteUserGoalMutation, DeleteUserGoalMutationVariables>;
export const GoalEditDocument = gql`
    mutation GoalEdit($goalId: String!, $input: EditGoalInput!) {
  editUserGoal(goalId: $goalId, input: $input) {
    user {
      ...MeUser
    }
  }
}
    ${MeUserFragmentDoc}`;
export type GoalEditMutationFn = Apollo.MutationFunction<GoalEditMutation, GoalEditMutationVariables>;

/**
 * __useGoalEditMutation__
 *
 * To run a mutation, you first call `useGoalEditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGoalEditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [goalEditMutation, { data, loading, error }] = useGoalEditMutation({
 *   variables: {
 *      goalId: // value for 'goalId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGoalEditMutation(baseOptions?: Apollo.MutationHookOptions<GoalEditMutation, GoalEditMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GoalEditMutation, GoalEditMutationVariables>(GoalEditDocument, options);
      }
export type GoalEditMutationHookResult = ReturnType<typeof useGoalEditMutation>;
export type GoalEditMutationResult = Apollo.MutationResult<GoalEditMutation>;
export type GoalEditMutationOptions = Apollo.BaseMutationOptions<GoalEditMutation, GoalEditMutationVariables>;
export const AddUserHolidayDocument = gql`
    mutation AddUserHoliday($userId: String!, $input: CreateHolidayInput!) {
  addUserHoliday(userId: $userId, input: $input) {
    user {
      ...MeUser
    }
  }
}
    ${MeUserFragmentDoc}`;
export type AddUserHolidayMutationFn = Apollo.MutationFunction<AddUserHolidayMutation, AddUserHolidayMutationVariables>;

/**
 * __useAddUserHolidayMutation__
 *
 * To run a mutation, you first call `useAddUserHolidayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserHolidayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserHolidayMutation, { data, loading, error }] = useAddUserHolidayMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddUserHolidayMutation(baseOptions?: Apollo.MutationHookOptions<AddUserHolidayMutation, AddUserHolidayMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddUserHolidayMutation, AddUserHolidayMutationVariables>(AddUserHolidayDocument, options);
      }
export type AddUserHolidayMutationHookResult = ReturnType<typeof useAddUserHolidayMutation>;
export type AddUserHolidayMutationResult = Apollo.MutationResult<AddUserHolidayMutation>;
export type AddUserHolidayMutationOptions = Apollo.BaseMutationOptions<AddUserHolidayMutation, AddUserHolidayMutationVariables>;
export const HolidayDeleteDocument = gql`
    mutation HolidayDelete($holidayId: String!) {
  deleteUserHoliday(holidayId: $holidayId) {
    user {
      ...MeUser
    }
  }
}
    ${MeUserFragmentDoc}`;
export type HolidayDeleteMutationFn = Apollo.MutationFunction<HolidayDeleteMutation, HolidayDeleteMutationVariables>;

/**
 * __useHolidayDeleteMutation__
 *
 * To run a mutation, you first call `useHolidayDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHolidayDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [holidayDeleteMutation, { data, loading, error }] = useHolidayDeleteMutation({
 *   variables: {
 *      holidayId: // value for 'holidayId'
 *   },
 * });
 */
export function useHolidayDeleteMutation(baseOptions?: Apollo.MutationHookOptions<HolidayDeleteMutation, HolidayDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HolidayDeleteMutation, HolidayDeleteMutationVariables>(HolidayDeleteDocument, options);
      }
export type HolidayDeleteMutationHookResult = ReturnType<typeof useHolidayDeleteMutation>;
export type HolidayDeleteMutationResult = Apollo.MutationResult<HolidayDeleteMutation>;
export type HolidayDeleteMutationOptions = Apollo.BaseMutationOptions<HolidayDeleteMutation, HolidayDeleteMutationVariables>;
export const HolidayEditDocument = gql`
    mutation HolidayEdit($holidayId: String!, $input: EditHolidayInput!) {
  editUserHoliday(holidayId: $holidayId, input: $input) {
    user {
      ...MeUser
    }
  }
}
    ${MeUserFragmentDoc}`;
export type HolidayEditMutationFn = Apollo.MutationFunction<HolidayEditMutation, HolidayEditMutationVariables>;

/**
 * __useHolidayEditMutation__
 *
 * To run a mutation, you first call `useHolidayEditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHolidayEditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [holidayEditMutation, { data, loading, error }] = useHolidayEditMutation({
 *   variables: {
 *      holidayId: // value for 'holidayId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHolidayEditMutation(baseOptions?: Apollo.MutationHookOptions<HolidayEditMutation, HolidayEditMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HolidayEditMutation, HolidayEditMutationVariables>(HolidayEditDocument, options);
      }
export type HolidayEditMutationHookResult = ReturnType<typeof useHolidayEditMutation>;
export type HolidayEditMutationResult = Apollo.MutationResult<HolidayEditMutation>;
export type HolidayEditMutationOptions = Apollo.BaseMutationOptions<HolidayEditMutation, HolidayEditMutationVariables>;
export const IncomeAddDocument = gql`
    mutation IncomeAdd($userId: String!, $input: CreateIncomeStreamInput!) {
  addUserIncome(userId: $userId, input: $input) {
    user {
      ...MeUser
    }
  }
}
    ${MeUserFragmentDoc}`;
export type IncomeAddMutationFn = Apollo.MutationFunction<IncomeAddMutation, IncomeAddMutationVariables>;

/**
 * __useIncomeAddMutation__
 *
 * To run a mutation, you first call `useIncomeAddMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIncomeAddMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [incomeAddMutation, { data, loading, error }] = useIncomeAddMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useIncomeAddMutation(baseOptions?: Apollo.MutationHookOptions<IncomeAddMutation, IncomeAddMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IncomeAddMutation, IncomeAddMutationVariables>(IncomeAddDocument, options);
      }
export type IncomeAddMutationHookResult = ReturnType<typeof useIncomeAddMutation>;
export type IncomeAddMutationResult = Apollo.MutationResult<IncomeAddMutation>;
export type IncomeAddMutationOptions = Apollo.BaseMutationOptions<IncomeAddMutation, IncomeAddMutationVariables>;
export const IncomeDeleteDocument = gql`
    mutation IncomeDelete($incomeId: String!) {
  deleteUserIncome(incomeId: $incomeId) {
    user {
      ...MeUser
    }
  }
}
    ${MeUserFragmentDoc}`;
export type IncomeDeleteMutationFn = Apollo.MutationFunction<IncomeDeleteMutation, IncomeDeleteMutationVariables>;

/**
 * __useIncomeDeleteMutation__
 *
 * To run a mutation, you first call `useIncomeDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIncomeDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [incomeDeleteMutation, { data, loading, error }] = useIncomeDeleteMutation({
 *   variables: {
 *      incomeId: // value for 'incomeId'
 *   },
 * });
 */
export function useIncomeDeleteMutation(baseOptions?: Apollo.MutationHookOptions<IncomeDeleteMutation, IncomeDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IncomeDeleteMutation, IncomeDeleteMutationVariables>(IncomeDeleteDocument, options);
      }
export type IncomeDeleteMutationHookResult = ReturnType<typeof useIncomeDeleteMutation>;
export type IncomeDeleteMutationResult = Apollo.MutationResult<IncomeDeleteMutation>;
export type IncomeDeleteMutationOptions = Apollo.BaseMutationOptions<IncomeDeleteMutation, IncomeDeleteMutationVariables>;
export const IncomeEditDocument = gql`
    mutation IncomeEdit($incomeId: String!, $input: EditIncomeStreamInput!) {
  editUserIncome(incomeId: $incomeId, input: $input) {
    user {
      ...MeUser
    }
  }
}
    ${MeUserFragmentDoc}`;
export type IncomeEditMutationFn = Apollo.MutationFunction<IncomeEditMutation, IncomeEditMutationVariables>;

/**
 * __useIncomeEditMutation__
 *
 * To run a mutation, you first call `useIncomeEditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIncomeEditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [incomeEditMutation, { data, loading, error }] = useIncomeEditMutation({
 *   variables: {
 *      incomeId: // value for 'incomeId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useIncomeEditMutation(baseOptions?: Apollo.MutationHookOptions<IncomeEditMutation, IncomeEditMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IncomeEditMutation, IncomeEditMutationVariables>(IncomeEditDocument, options);
      }
export type IncomeEditMutationHookResult = ReturnType<typeof useIncomeEditMutation>;
export type IncomeEditMutationResult = Apollo.MutationResult<IncomeEditMutation>;
export type IncomeEditMutationOptions = Apollo.BaseMutationOptions<IncomeEditMutation, IncomeEditMutationVariables>;
export const InvestmentPropertyAddDocument = gql`
    mutation InvestmentPropertyAdd($userId: String!) {
  addInvestmentProperty(userId: $userId) {
    user {
      ...MeUser
    }
    createdProperty {
      id
    }
  }
}
    ${MeUserFragmentDoc}`;
export type InvestmentPropertyAddMutationFn = Apollo.MutationFunction<InvestmentPropertyAddMutation, InvestmentPropertyAddMutationVariables>;

/**
 * __useInvestmentPropertyAddMutation__
 *
 * To run a mutation, you first call `useInvestmentPropertyAddMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInvestmentPropertyAddMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [investmentPropertyAddMutation, { data, loading, error }] = useInvestmentPropertyAddMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useInvestmentPropertyAddMutation(baseOptions?: Apollo.MutationHookOptions<InvestmentPropertyAddMutation, InvestmentPropertyAddMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InvestmentPropertyAddMutation, InvestmentPropertyAddMutationVariables>(InvestmentPropertyAddDocument, options);
      }
export type InvestmentPropertyAddMutationHookResult = ReturnType<typeof useInvestmentPropertyAddMutation>;
export type InvestmentPropertyAddMutationResult = Apollo.MutationResult<InvestmentPropertyAddMutation>;
export type InvestmentPropertyAddMutationOptions = Apollo.BaseMutationOptions<InvestmentPropertyAddMutation, InvestmentPropertyAddMutationVariables>;
export const InvestmentPropertyDeleteDocument = gql`
    mutation InvestmentPropertyDelete($propertyId: String!) {
  deleteInvestmentProperty(propertyId: $propertyId) {
    user {
      ...MeUser
    }
  }
}
    ${MeUserFragmentDoc}`;
export type InvestmentPropertyDeleteMutationFn = Apollo.MutationFunction<InvestmentPropertyDeleteMutation, InvestmentPropertyDeleteMutationVariables>;

/**
 * __useInvestmentPropertyDeleteMutation__
 *
 * To run a mutation, you first call `useInvestmentPropertyDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInvestmentPropertyDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [investmentPropertyDeleteMutation, { data, loading, error }] = useInvestmentPropertyDeleteMutation({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *   },
 * });
 */
export function useInvestmentPropertyDeleteMutation(baseOptions?: Apollo.MutationHookOptions<InvestmentPropertyDeleteMutation, InvestmentPropertyDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InvestmentPropertyDeleteMutation, InvestmentPropertyDeleteMutationVariables>(InvestmentPropertyDeleteDocument, options);
      }
export type InvestmentPropertyDeleteMutationHookResult = ReturnType<typeof useInvestmentPropertyDeleteMutation>;
export type InvestmentPropertyDeleteMutationResult = Apollo.MutationResult<InvestmentPropertyDeleteMutation>;
export type InvestmentPropertyDeleteMutationOptions = Apollo.BaseMutationOptions<InvestmentPropertyDeleteMutation, InvestmentPropertyDeleteMutationVariables>;
export const InvestmentPropertyEditDocument = gql`
    mutation InvestmentPropertyEdit($propertyId: String!, $input: EditInvestmentPropertyInput!) {
  editInvestmentProperty(propertyId: $propertyId, propertyInput: $input) {
    user {
      ...MeUser
    }
  }
}
    ${MeUserFragmentDoc}`;
export type InvestmentPropertyEditMutationFn = Apollo.MutationFunction<InvestmentPropertyEditMutation, InvestmentPropertyEditMutationVariables>;

/**
 * __useInvestmentPropertyEditMutation__
 *
 * To run a mutation, you first call `useInvestmentPropertyEditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInvestmentPropertyEditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [investmentPropertyEditMutation, { data, loading, error }] = useInvestmentPropertyEditMutation({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInvestmentPropertyEditMutation(baseOptions?: Apollo.MutationHookOptions<InvestmentPropertyEditMutation, InvestmentPropertyEditMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InvestmentPropertyEditMutation, InvestmentPropertyEditMutationVariables>(InvestmentPropertyEditDocument, options);
      }
export type InvestmentPropertyEditMutationHookResult = ReturnType<typeof useInvestmentPropertyEditMutation>;
export type InvestmentPropertyEditMutationResult = Apollo.MutationResult<InvestmentPropertyEditMutation>;
export type InvestmentPropertyEditMutationOptions = Apollo.BaseMutationOptions<InvestmentPropertyEditMutation, InvestmentPropertyEditMutationVariables>;
export const InvestmentPropertyUpdateDocument = gql`
    mutation InvestmentPropertyUpdate($propertyId: String!, $input: UpdateInvestmentPropertyInput!) {
  updateInvestmentProperty(propertyId: $propertyId, propertyInput: $input) {
    user {
      ...MeUser
    }
  }
}
    ${MeUserFragmentDoc}`;
export type InvestmentPropertyUpdateMutationFn = Apollo.MutationFunction<InvestmentPropertyUpdateMutation, InvestmentPropertyUpdateMutationVariables>;

/**
 * __useInvestmentPropertyUpdateMutation__
 *
 * To run a mutation, you first call `useInvestmentPropertyUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInvestmentPropertyUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [investmentPropertyUpdateMutation, { data, loading, error }] = useInvestmentPropertyUpdateMutation({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInvestmentPropertyUpdateMutation(baseOptions?: Apollo.MutationHookOptions<InvestmentPropertyUpdateMutation, InvestmentPropertyUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InvestmentPropertyUpdateMutation, InvestmentPropertyUpdateMutationVariables>(InvestmentPropertyUpdateDocument, options);
      }
export type InvestmentPropertyUpdateMutationHookResult = ReturnType<typeof useInvestmentPropertyUpdateMutation>;
export type InvestmentPropertyUpdateMutationResult = Apollo.MutationResult<InvestmentPropertyUpdateMutation>;
export type InvestmentPropertyUpdateMutationOptions = Apollo.BaseMutationOptions<InvestmentPropertyUpdateMutation, InvestmentPropertyUpdateMutationVariables>;
export const ProjectedSavingEditDocument = gql`
    mutation ProjectedSavingEdit($userId: String!, $input: ProjectedSavingEditInput!) {
  editProjectedSaving(userId: $userId, input: $input) {
    user {
      ...MeUser
    }
  }
}
    ${MeUserFragmentDoc}`;
export type ProjectedSavingEditMutationFn = Apollo.MutationFunction<ProjectedSavingEditMutation, ProjectedSavingEditMutationVariables>;

/**
 * __useProjectedSavingEditMutation__
 *
 * To run a mutation, you first call `useProjectedSavingEditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProjectedSavingEditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [projectedSavingEditMutation, { data, loading, error }] = useProjectedSavingEditMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProjectedSavingEditMutation(baseOptions?: Apollo.MutationHookOptions<ProjectedSavingEditMutation, ProjectedSavingEditMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProjectedSavingEditMutation, ProjectedSavingEditMutationVariables>(ProjectedSavingEditDocument, options);
      }
export type ProjectedSavingEditMutationHookResult = ReturnType<typeof useProjectedSavingEditMutation>;
export type ProjectedSavingEditMutationResult = Apollo.MutationResult<ProjectedSavingEditMutation>;
export type ProjectedSavingEditMutationOptions = Apollo.BaseMutationOptions<ProjectedSavingEditMutation, ProjectedSavingEditMutationVariables>;
export const UndoPlannedPurchaseDocument = gql`
    mutation UndoPlannedPurchase($userId: String!) {
  undoPlannedPurchase(userId: $userId) {
    user {
      ...MeUser
    }
  }
}
    ${MeUserFragmentDoc}`;
export type UndoPlannedPurchaseMutationFn = Apollo.MutationFunction<UndoPlannedPurchaseMutation, UndoPlannedPurchaseMutationVariables>;

/**
 * __useUndoPlannedPurchaseMutation__
 *
 * To run a mutation, you first call `useUndoPlannedPurchaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUndoPlannedPurchaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [undoPlannedPurchaseMutation, { data, loading, error }] = useUndoPlannedPurchaseMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUndoPlannedPurchaseMutation(baseOptions?: Apollo.MutationHookOptions<UndoPlannedPurchaseMutation, UndoPlannedPurchaseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UndoPlannedPurchaseMutation, UndoPlannedPurchaseMutationVariables>(UndoPlannedPurchaseDocument, options);
      }
export type UndoPlannedPurchaseMutationHookResult = ReturnType<typeof useUndoPlannedPurchaseMutation>;
export type UndoPlannedPurchaseMutationResult = Apollo.MutationResult<UndoPlannedPurchaseMutation>;
export type UndoPlannedPurchaseMutationOptions = Apollo.BaseMutationOptions<UndoPlannedPurchaseMutation, UndoPlannedPurchaseMutationVariables>;
export const UpdateUserPasswordDocument = gql`
    mutation UpdateUserPassword($input: UpdatePasswordInput!) {
  updatePassword(input: $input) {
    user {
      ...MeUser
    }
  }
}
    ${MeUserFragmentDoc}`;
export type UpdateUserPasswordMutationFn = Apollo.MutationFunction<UpdateUserPasswordMutation, UpdateUserPasswordMutationVariables>;

/**
 * __useUpdateUserPasswordMutation__
 *
 * To run a mutation, you first call `useUpdateUserPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserPasswordMutation, { data, loading, error }] = useUpdateUserPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserPasswordMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserPasswordMutation, UpdateUserPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserPasswordMutation, UpdateUserPasswordMutationVariables>(UpdateUserPasswordDocument, options);
      }
export type UpdateUserPasswordMutationHookResult = ReturnType<typeof useUpdateUserPasswordMutation>;
export type UpdateUserPasswordMutationResult = Apollo.MutationResult<UpdateUserPasswordMutation>;
export type UpdateUserPasswordMutationOptions = Apollo.BaseMutationOptions<UpdateUserPasswordMutation, UpdateUserPasswordMutationVariables>;
export const UserDeleteDocument = gql`
    mutation UserDelete($userId: String!) {
  deleteUser(userId: $userId)
}
    `;
export type UserDeleteMutationFn = Apollo.MutationFunction<UserDeleteMutation, UserDeleteMutationVariables>;

/**
 * __useUserDeleteMutation__
 *
 * To run a mutation, you first call `useUserDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userDeleteMutation, { data, loading, error }] = useUserDeleteMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserDeleteMutation(baseOptions?: Apollo.MutationHookOptions<UserDeleteMutation, UserDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserDeleteMutation, UserDeleteMutationVariables>(UserDeleteDocument, options);
      }
export type UserDeleteMutationHookResult = ReturnType<typeof useUserDeleteMutation>;
export type UserDeleteMutationResult = Apollo.MutationResult<UserDeleteMutation>;
export type UserDeleteMutationOptions = Apollo.BaseMutationOptions<UserDeleteMutation, UserDeleteMutationVariables>;
export const UserInfoEditDocument = gql`
    mutation UserInfoEdit($userId: String!, $input: EditUserInfoInput!) {
  editUserInfo(userId: $userId, input: $input) {
    user {
      ...MeUser
    }
  }
}
    ${MeUserFragmentDoc}`;
export type UserInfoEditMutationFn = Apollo.MutationFunction<UserInfoEditMutation, UserInfoEditMutationVariables>;

/**
 * __useUserInfoEditMutation__
 *
 * To run a mutation, you first call `useUserInfoEditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserInfoEditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userInfoEditMutation, { data, loading, error }] = useUserInfoEditMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserInfoEditMutation(baseOptions?: Apollo.MutationHookOptions<UserInfoEditMutation, UserInfoEditMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserInfoEditMutation, UserInfoEditMutationVariables>(UserInfoEditDocument, options);
      }
export type UserInfoEditMutationHookResult = ReturnType<typeof useUserInfoEditMutation>;
export type UserInfoEditMutationResult = Apollo.MutationResult<UserInfoEditMutation>;
export type UserInfoEditMutationOptions = Apollo.BaseMutationOptions<UserInfoEditMutation, UserInfoEditMutationVariables>;
export const UserPersoanlCoachStatusEditDocument = gql`
    mutation UserPersoanlCoachStatusEdit($userId: String!, $personalCoachStatus: PersonalCoachStatus!) {
  editUserPersonalCoachStatus(
    userId: $userId
    personalCoachStatus: $personalCoachStatus
  ) {
    user {
      ...MeUser
    }
  }
}
    ${MeUserFragmentDoc}`;
export type UserPersoanlCoachStatusEditMutationFn = Apollo.MutationFunction<UserPersoanlCoachStatusEditMutation, UserPersoanlCoachStatusEditMutationVariables>;

/**
 * __useUserPersoanlCoachStatusEditMutation__
 *
 * To run a mutation, you first call `useUserPersoanlCoachStatusEditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserPersoanlCoachStatusEditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userPersoanlCoachStatusEditMutation, { data, loading, error }] = useUserPersoanlCoachStatusEditMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      personalCoachStatus: // value for 'personalCoachStatus'
 *   },
 * });
 */
export function useUserPersoanlCoachStatusEditMutation(baseOptions?: Apollo.MutationHookOptions<UserPersoanlCoachStatusEditMutation, UserPersoanlCoachStatusEditMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserPersoanlCoachStatusEditMutation, UserPersoanlCoachStatusEditMutationVariables>(UserPersoanlCoachStatusEditDocument, options);
      }
export type UserPersoanlCoachStatusEditMutationHookResult = ReturnType<typeof useUserPersoanlCoachStatusEditMutation>;
export type UserPersoanlCoachStatusEditMutationResult = Apollo.MutationResult<UserPersoanlCoachStatusEditMutation>;
export type UserPersoanlCoachStatusEditMutationOptions = Apollo.BaseMutationOptions<UserPersoanlCoachStatusEditMutation, UserPersoanlCoachStatusEditMutationVariables>;
export const UserRoleEditDocument = gql`
    mutation UserRoleEdit($userId: String!, $role: UserRole!) {
  editUserRole(userId: $userId, role: $role) {
    user {
      ...MeUser
    }
  }
}
    ${MeUserFragmentDoc}`;
export type UserRoleEditMutationFn = Apollo.MutationFunction<UserRoleEditMutation, UserRoleEditMutationVariables>;

/**
 * __useUserRoleEditMutation__
 *
 * To run a mutation, you first call `useUserRoleEditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserRoleEditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userRoleEditMutation, { data, loading, error }] = useUserRoleEditMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useUserRoleEditMutation(baseOptions?: Apollo.MutationHookOptions<UserRoleEditMutation, UserRoleEditMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserRoleEditMutation, UserRoleEditMutationVariables>(UserRoleEditDocument, options);
      }
export type UserRoleEditMutationHookResult = ReturnType<typeof useUserRoleEditMutation>;
export type UserRoleEditMutationResult = Apollo.MutationResult<UserRoleEditMutation>;
export type UserRoleEditMutationOptions = Apollo.BaseMutationOptions<UserRoleEditMutation, UserRoleEditMutationVariables>;
export const AllUsersDocument = gql`
    query AllUsers($filter: String, $sort: SortInput, $pagination: PaginationInput) {
  users(filter: $filter, sort: $sort, pagination: $pagination) {
    users {
      id
      email
      role
      personalCoachStatus
      name
      createdAt
      updatedAt
    }
    total
  }
}
    `;

/**
 * __useAllUsersQuery__
 *
 * To run a query within a React component, call `useAllUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllUsersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useAllUsersQuery(baseOptions?: Apollo.QueryHookOptions<AllUsersQuery, AllUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllUsersQuery, AllUsersQueryVariables>(AllUsersDocument, options);
      }
export function useAllUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllUsersQuery, AllUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllUsersQuery, AllUsersQueryVariables>(AllUsersDocument, options);
        }
export type AllUsersQueryHookResult = ReturnType<typeof useAllUsersQuery>;
export type AllUsersLazyQueryHookResult = ReturnType<typeof useAllUsersLazyQuery>;
export type AllUsersQueryResult = Apollo.QueryResult<AllUsersQuery, AllUsersQueryVariables>;
export const ForgotPasswordDocument = gql`
    query ForgotPassword($email: String!) {
  forgotPassword(email: $email)
}
    `;

/**
 * __useForgotPasswordQuery__
 *
 * To run a query within a React component, call `useForgotPasswordQuery` and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useForgotPasswordQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useForgotPasswordQuery(baseOptions: Apollo.QueryHookOptions<ForgotPasswordQuery, ForgotPasswordQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ForgotPasswordQuery, ForgotPasswordQueryVariables>(ForgotPasswordDocument, options);
      }
export function useForgotPasswordLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ForgotPasswordQuery, ForgotPasswordQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ForgotPasswordQuery, ForgotPasswordQueryVariables>(ForgotPasswordDocument, options);
        }
export type ForgotPasswordQueryHookResult = ReturnType<typeof useForgotPasswordQuery>;
export type ForgotPasswordLazyQueryHookResult = ReturnType<typeof useForgotPasswordLazyQuery>;
export type ForgotPasswordQueryResult = Apollo.QueryResult<ForgotPasswordQuery, ForgotPasswordQueryVariables>;
export const GetAllUsersListDocument = gql`
    query GetAllUsersList($filter: String, $sort: SortInput, $pagination: PaginationInput) {
  getAllUsersList(filter: $filter, sort: $sort, pagination: $pagination) {
    users {
      id
      email
      role
      personalCoachStatus
      userInfo {
        name
        brokerId
      }
    }
    total
    currentPage
  }
}
    `;

/**
 * __useGetAllUsersListQuery__
 *
 * To run a query within a React component, call `useGetAllUsersListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllUsersListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllUsersListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetAllUsersListQuery(baseOptions?: Apollo.QueryHookOptions<GetAllUsersListQuery, GetAllUsersListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllUsersListQuery, GetAllUsersListQueryVariables>(GetAllUsersListDocument, options);
      }
export function useGetAllUsersListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllUsersListQuery, GetAllUsersListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllUsersListQuery, GetAllUsersListQueryVariables>(GetAllUsersListDocument, options);
        }
export type GetAllUsersListQueryHookResult = ReturnType<typeof useGetAllUsersListQuery>;
export type GetAllUsersListLazyQueryHookResult = ReturnType<typeof useGetAllUsersListLazyQuery>;
export type GetAllUsersListQueryResult = Apollo.QueryResult<GetAllUsersListQuery, GetAllUsersListQueryVariables>;
export const GetBasiqConsentUrlDocument = gql`
    query GetBasiqConsentUrl($userId: String!, $type: String!) {
  getBasiqConsentUrl(userId: $userId, type: $type)
}
    `;

/**
 * __useGetBasiqConsentUrlQuery__
 *
 * To run a query within a React component, call `useGetBasiqConsentUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBasiqConsentUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBasiqConsentUrlQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useGetBasiqConsentUrlQuery(baseOptions: Apollo.QueryHookOptions<GetBasiqConsentUrlQuery, GetBasiqConsentUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBasiqConsentUrlQuery, GetBasiqConsentUrlQueryVariables>(GetBasiqConsentUrlDocument, options);
      }
export function useGetBasiqConsentUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBasiqConsentUrlQuery, GetBasiqConsentUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBasiqConsentUrlQuery, GetBasiqConsentUrlQueryVariables>(GetBasiqConsentUrlDocument, options);
        }
export type GetBasiqConsentUrlQueryHookResult = ReturnType<typeof useGetBasiqConsentUrlQuery>;
export type GetBasiqConsentUrlLazyQueryHookResult = ReturnType<typeof useGetBasiqConsentUrlLazyQuery>;
export type GetBasiqConsentUrlQueryResult = Apollo.QueryResult<GetBasiqConsentUrlQuery, GetBasiqConsentUrlQueryVariables>;
export const GetBasiqExpenseLogDocument = gql`
    query GetBasiqExpenseLog($userId: String!, $expenseType: String!, $expenseId: String!) {
  getBasiqExpenseLog(
    userId: $userId
    expenseType: $expenseType
    expenseId: $expenseId
  ) {
    id
    userId
    label
    category
    frequency
    amount
    histroyInfo {
      id
      expense_id
      amount
      basiqCategoryJson {
        basiqCategoryData {
          classCode
          classTitle
          divisionTitle
          changeHistory {
            date
            amount
          }
          avgMonthly
        }
        totalAvgAmount
      }
      created_at
    }
  }
}
    `;

/**
 * __useGetBasiqExpenseLogQuery__
 *
 * To run a query within a React component, call `useGetBasiqExpenseLogQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBasiqExpenseLogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBasiqExpenseLogQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      expenseType: // value for 'expenseType'
 *      expenseId: // value for 'expenseId'
 *   },
 * });
 */
export function useGetBasiqExpenseLogQuery(baseOptions: Apollo.QueryHookOptions<GetBasiqExpenseLogQuery, GetBasiqExpenseLogQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBasiqExpenseLogQuery, GetBasiqExpenseLogQueryVariables>(GetBasiqExpenseLogDocument, options);
      }
export function useGetBasiqExpenseLogLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBasiqExpenseLogQuery, GetBasiqExpenseLogQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBasiqExpenseLogQuery, GetBasiqExpenseLogQueryVariables>(GetBasiqExpenseLogDocument, options);
        }
export type GetBasiqExpenseLogQueryHookResult = ReturnType<typeof useGetBasiqExpenseLogQuery>;
export type GetBasiqExpenseLogLazyQueryHookResult = ReturnType<typeof useGetBasiqExpenseLogLazyQuery>;
export type GetBasiqExpenseLogQueryResult = Apollo.QueryResult<GetBasiqExpenseLogQuery, GetBasiqExpenseLogQueryVariables>;
export const GetBasiqUserDetailsDocument = gql`
    query GetBasiqUserDetails($userId: String!) {
  getBasiqUserDetails(userId: $userId) {
    type
    email
    mobile
  }
}
    `;

/**
 * __useGetBasiqUserDetailsQuery__
 *
 * To run a query within a React component, call `useGetBasiqUserDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBasiqUserDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBasiqUserDetailsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetBasiqUserDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetBasiqUserDetailsQuery, GetBasiqUserDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBasiqUserDetailsQuery, GetBasiqUserDetailsQueryVariables>(GetBasiqUserDetailsDocument, options);
      }
export function useGetBasiqUserDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBasiqUserDetailsQuery, GetBasiqUserDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBasiqUserDetailsQuery, GetBasiqUserDetailsQueryVariables>(GetBasiqUserDetailsDocument, options);
        }
export type GetBasiqUserDetailsQueryHookResult = ReturnType<typeof useGetBasiqUserDetailsQuery>;
export type GetBasiqUserDetailsLazyQueryHookResult = ReturnType<typeof useGetBasiqUserDetailsLazyQuery>;
export type GetBasiqUserDetailsQueryResult = Apollo.QueryResult<GetBasiqUserDetailsQuery, GetBasiqUserDetailsQueryVariables>;
export const GetUserBasiqExpensesDocument = gql`
    query GetUserBasiqExpenses($userId: String!) {
  getUserBasiqExpenses(userId: $userId) {
    ...bankUserExpenseType
  }
}
    ${BankUserExpenseTypeFragmentDoc}`;

/**
 * __useGetUserBasiqExpensesQuery__
 *
 * To run a query within a React component, call `useGetUserBasiqExpensesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserBasiqExpensesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserBasiqExpensesQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserBasiqExpensesQuery(baseOptions: Apollo.QueryHookOptions<GetUserBasiqExpensesQuery, GetUserBasiqExpensesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserBasiqExpensesQuery, GetUserBasiqExpensesQueryVariables>(GetUserBasiqExpensesDocument, options);
      }
export function useGetUserBasiqExpensesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserBasiqExpensesQuery, GetUserBasiqExpensesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserBasiqExpensesQuery, GetUserBasiqExpensesQueryVariables>(GetUserBasiqExpensesDocument, options);
        }
export type GetUserBasiqExpensesQueryHookResult = ReturnType<typeof useGetUserBasiqExpensesQuery>;
export type GetUserBasiqExpensesLazyQueryHookResult = ReturnType<typeof useGetUserBasiqExpensesLazyQuery>;
export type GetUserBasiqExpensesQueryResult = Apollo.QueryResult<GetUserBasiqExpensesQuery, GetUserBasiqExpensesQueryVariables>;
export const GetBrokerAllUsersListDocument = gql`
    query GetBrokerAllUsersList($filter: String, $sort: SortInput, $pagination: PaginationInput) {
  getBrokerAllUsersList(filter: $filter, sort: $sort, pagination: $pagination) {
    users {
      id
      email
      role
      personalCoachStatus
      userInfo {
        name
        brokerId
      }
    }
    total
    currentPage
  }
}
    `;

/**
 * __useGetBrokerAllUsersListQuery__
 *
 * To run a query within a React component, call `useGetBrokerAllUsersListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBrokerAllUsersListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBrokerAllUsersListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetBrokerAllUsersListQuery(baseOptions?: Apollo.QueryHookOptions<GetBrokerAllUsersListQuery, GetBrokerAllUsersListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBrokerAllUsersListQuery, GetBrokerAllUsersListQueryVariables>(GetBrokerAllUsersListDocument, options);
      }
export function useGetBrokerAllUsersListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBrokerAllUsersListQuery, GetBrokerAllUsersListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBrokerAllUsersListQuery, GetBrokerAllUsersListQueryVariables>(GetBrokerAllUsersListDocument, options);
        }
export type GetBrokerAllUsersListQueryHookResult = ReturnType<typeof useGetBrokerAllUsersListQuery>;
export type GetBrokerAllUsersListLazyQueryHookResult = ReturnType<typeof useGetBrokerAllUsersListLazyQuery>;
export type GetBrokerAllUsersListQueryResult = Apollo.QueryResult<GetBrokerAllUsersListQuery, GetBrokerAllUsersListQueryVariables>;
export const GetBrokerDetailsDocument = gql`
    query GetBrokerDetails($userId: String!) {
  user(id: $userId) {
    __typename
    id
    ...MeUser
  }
}
    ${MeUserFragmentDoc}`;

/**
 * __useGetBrokerDetailsQuery__
 *
 * To run a query within a React component, call `useGetBrokerDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBrokerDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBrokerDetailsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetBrokerDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetBrokerDetailsQuery, GetBrokerDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBrokerDetailsQuery, GetBrokerDetailsQueryVariables>(GetBrokerDetailsDocument, options);
      }
export function useGetBrokerDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBrokerDetailsQuery, GetBrokerDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBrokerDetailsQuery, GetBrokerDetailsQueryVariables>(GetBrokerDetailsDocument, options);
        }
export type GetBrokerDetailsQueryHookResult = ReturnType<typeof useGetBrokerDetailsQuery>;
export type GetBrokerDetailsLazyQueryHookResult = ReturnType<typeof useGetBrokerDetailsLazyQuery>;
export type GetBrokerDetailsQueryResult = Apollo.QueryResult<GetBrokerDetailsQuery, GetBrokerDetailsQueryVariables>;
export const GetBrokerUsersListDocument = gql`
    query GetBrokerUsersList($pagination: PaginationInput) {
  getBrokerUsersList(pagination: $pagination) {
    users {
      id
      email
      role
      userInfo {
        name
      }
    }
    total
  }
}
    `;

/**
 * __useGetBrokerUsersListQuery__
 *
 * To run a query within a React component, call `useGetBrokerUsersListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBrokerUsersListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBrokerUsersListQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetBrokerUsersListQuery(baseOptions?: Apollo.QueryHookOptions<GetBrokerUsersListQuery, GetBrokerUsersListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBrokerUsersListQuery, GetBrokerUsersListQueryVariables>(GetBrokerUsersListDocument, options);
      }
export function useGetBrokerUsersListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBrokerUsersListQuery, GetBrokerUsersListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBrokerUsersListQuery, GetBrokerUsersListQueryVariables>(GetBrokerUsersListDocument, options);
        }
export type GetBrokerUsersListQueryHookResult = ReturnType<typeof useGetBrokerUsersListQuery>;
export type GetBrokerUsersListLazyQueryHookResult = ReturnType<typeof useGetBrokerUsersListLazyQuery>;
export type GetBrokerUsersListQueryResult = Apollo.QueryResult<GetBrokerUsersListQuery, GetBrokerUsersListQueryVariables>;
export const GetUserBasiqAccountsDocument = gql`
    query GetUserBasiqAccounts($userId: String!) {
  getUserBasiqAccounts(userId: $userId) {
    ...bankAccountType
  }
}
    ${BankAccountTypeFragmentDoc}`;

/**
 * __useGetUserBasiqAccountsQuery__
 *
 * To run a query within a React component, call `useGetUserBasiqAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserBasiqAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserBasiqAccountsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserBasiqAccountsQuery(baseOptions: Apollo.QueryHookOptions<GetUserBasiqAccountsQuery, GetUserBasiqAccountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserBasiqAccountsQuery, GetUserBasiqAccountsQueryVariables>(GetUserBasiqAccountsDocument, options);
      }
export function useGetUserBasiqAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserBasiqAccountsQuery, GetUserBasiqAccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserBasiqAccountsQuery, GetUserBasiqAccountsQueryVariables>(GetUserBasiqAccountsDocument, options);
        }
export type GetUserBasiqAccountsQueryHookResult = ReturnType<typeof useGetUserBasiqAccountsQuery>;
export type GetUserBasiqAccountsLazyQueryHookResult = ReturnType<typeof useGetUserBasiqAccountsLazyQuery>;
export type GetUserBasiqAccountsQueryResult = Apollo.QueryResult<GetUserBasiqAccountsQuery, GetUserBasiqAccountsQueryVariables>;
export const GetUserDetailsDocument = gql`
    query GetUserDetails($userId: String!) {
  getUserDetails(userId: $userId) {
    user {
      id
      email
      role
      subscriptionId
      personalCoachStatus
      personalCoachSubscriptionId
      name
      createdAt
      updatedAt
    }
    subscriptionData {
      list {
        id
        customer {
          allow_direct_debit
          auto_collection
          card_status
          created_at
          deleted
          excess_payments
          first_name
          id
          last_name
          net_term_days
          pii_cleared
          preferred_currency_code
          promotional_credits
          refundable_credits
          taxability
          unbilled_charges
          updated_at
        }
        subscription {
          activated_at
          billing_period
          billing_period_unit
          created_at
          currency_code
          current_term_end
          current_term_start
          customer_id
          id
          next_billing_at
          status
          subscription_items {
            amount
            free_quantity
            item_price_id
            item_type
            quantity
            unit_price
          }
          updated_at
        }
      }
    }
  }
}
    `;

/**
 * __useGetUserDetailsQuery__
 *
 * To run a query within a React component, call `useGetUserDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserDetailsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetUserDetailsQuery, GetUserDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserDetailsQuery, GetUserDetailsQueryVariables>(GetUserDetailsDocument, options);
      }
export function useGetUserDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserDetailsQuery, GetUserDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserDetailsQuery, GetUserDetailsQueryVariables>(GetUserDetailsDocument, options);
        }
export type GetUserDetailsQueryHookResult = ReturnType<typeof useGetUserDetailsQuery>;
export type GetUserDetailsLazyQueryHookResult = ReturnType<typeof useGetUserDetailsLazyQuery>;
export type GetUserDetailsQueryResult = Apollo.QueryResult<GetUserDetailsQuery, GetUserDetailsQueryVariables>;
export const MeUserDocument = gql`
    query MeUser($userId: String!) {
  user(id: $userId) {
    __typename
    id
    ...MeUser
  }
}
    ${MeUserFragmentDoc}`;

/**
 * __useMeUserQuery__
 *
 * To run a query within a React component, call `useMeUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useMeUserQuery(baseOptions: Apollo.QueryHookOptions<MeUserQuery, MeUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeUserQuery, MeUserQueryVariables>(MeUserDocument, options);
      }
export function useMeUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeUserQuery, MeUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeUserQuery, MeUserQueryVariables>(MeUserDocument, options);
        }
export type MeUserQueryHookResult = ReturnType<typeof useMeUserQuery>;
export type MeUserLazyQueryHookResult = ReturnType<typeof useMeUserLazyQuery>;
export type MeUserQueryResult = Apollo.QueryResult<MeUserQuery, MeUserQueryVariables>;
export const ResetPasswordDocument = gql`
    mutation resetPassword($userId: String!) {
  resetPassword(userId: $userId)
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const OtpVerifyDocument = gql`
    query otpVerify($userId: String!, $otp: String!) {
  otpVerify(userId: $userId, otp: $otp) {
    user {
      id
      email
      role
    }
    token
  }
}
    `;

/**
 * __useOtpVerifyQuery__
 *
 * To run a query within a React component, call `useOtpVerifyQuery` and pass it any options that fit your needs.
 * When your component renders, `useOtpVerifyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOtpVerifyQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      otp: // value for 'otp'
 *   },
 * });
 */
export function useOtpVerifyQuery(baseOptions: Apollo.QueryHookOptions<OtpVerifyQuery, OtpVerifyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OtpVerifyQuery, OtpVerifyQueryVariables>(OtpVerifyDocument, options);
      }
export function useOtpVerifyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OtpVerifyQuery, OtpVerifyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OtpVerifyQuery, OtpVerifyQueryVariables>(OtpVerifyDocument, options);
        }
export type OtpVerifyQueryHookResult = ReturnType<typeof useOtpVerifyQuery>;
export type OtpVerifyLazyQueryHookResult = ReturnType<typeof useOtpVerifyLazyQuery>;
export type OtpVerifyQueryResult = Apollo.QueryResult<OtpVerifyQuery, OtpVerifyQueryVariables>;
export const SigninDocument = gql`
    mutation Signin($email: String!, $password: String!) {
  signIn(input: {email: $email, password: $password}) {
    user {
      id
      email
      role
    }
    token
  }
}
    `;
export type SigninMutationFn = Apollo.MutationFunction<SigninMutation, SigninMutationVariables>;

/**
 * __useSigninMutation__
 *
 * To run a mutation, you first call `useSigninMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSigninMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signinMutation, { data, loading, error }] = useSigninMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useSigninMutation(baseOptions?: Apollo.MutationHookOptions<SigninMutation, SigninMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SigninMutation, SigninMutationVariables>(SigninDocument, options);
      }
export type SigninMutationHookResult = ReturnType<typeof useSigninMutation>;
export type SigninMutationResult = Apollo.MutationResult<SigninMutation>;
export type SigninMutationOptions = Apollo.BaseMutationOptions<SigninMutation, SigninMutationVariables>;
export const SignupDocument = gql`
    mutation Signup($input: SignUpInput!) {
  signUp(input: $input) {
    user {
      id
      email
      role
    }
    token
  }
}
    `;
export type SignupMutationFn = Apollo.MutationFunction<SignupMutation, SignupMutationVariables>;

/**
 * __useSignupMutation__
 *
 * To run a mutation, you first call `useSignupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signupMutation, { data, loading, error }] = useSignupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignupMutation(baseOptions?: Apollo.MutationHookOptions<SignupMutation, SignupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignupMutation, SignupMutationVariables>(SignupDocument, options);
      }
export type SignupMutationHookResult = ReturnType<typeof useSignupMutation>;
export type SignupMutationResult = Apollo.MutationResult<SignupMutation>;
export type SignupMutationOptions = Apollo.BaseMutationOptions<SignupMutation, SignupMutationVariables>;
export const UpdateResetPasswordDocument = gql`
    query UpdateResetPassword($password: String!, $token: String!) {
  updateResetPassword(password: $password, token: $token)
}
    `;

/**
 * __useUpdateResetPasswordQuery__
 *
 * To run a query within a React component, call `useUpdateResetPasswordQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpdateResetPasswordQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdateResetPasswordQuery({
 *   variables: {
 *      password: // value for 'password'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useUpdateResetPasswordQuery(baseOptions: Apollo.QueryHookOptions<UpdateResetPasswordQuery, UpdateResetPasswordQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UpdateResetPasswordQuery, UpdateResetPasswordQueryVariables>(UpdateResetPasswordDocument, options);
      }
export function useUpdateResetPasswordLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UpdateResetPasswordQuery, UpdateResetPasswordQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UpdateResetPasswordQuery, UpdateResetPasswordQueryVariables>(UpdateResetPasswordDocument, options);
        }
export type UpdateResetPasswordQueryHookResult = ReturnType<typeof useUpdateResetPasswordQuery>;
export type UpdateResetPasswordLazyQueryHookResult = ReturnType<typeof useUpdateResetPasswordLazyQuery>;
export type UpdateResetPasswordQueryResult = Apollo.QueryResult<UpdateResetPasswordQuery, UpdateResetPasswordQueryVariables>;
export const UserWithPaymentInfoLinkDocument = gql`
    query UserWithPaymentInfoLink($type: String!, $frequency: String!) {
  checkoutHostedPage(type: $type, frequency: $frequency)
}
    `;

/**
 * __useUserWithPaymentInfoLinkQuery__
 *
 * To run a query within a React component, call `useUserWithPaymentInfoLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserWithPaymentInfoLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserWithPaymentInfoLinkQuery({
 *   variables: {
 *      type: // value for 'type'
 *      frequency: // value for 'frequency'
 *   },
 * });
 */
export function useUserWithPaymentInfoLinkQuery(baseOptions: Apollo.QueryHookOptions<UserWithPaymentInfoLinkQuery, UserWithPaymentInfoLinkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserWithPaymentInfoLinkQuery, UserWithPaymentInfoLinkQueryVariables>(UserWithPaymentInfoLinkDocument, options);
      }
export function useUserWithPaymentInfoLinkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserWithPaymentInfoLinkQuery, UserWithPaymentInfoLinkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserWithPaymentInfoLinkQuery, UserWithPaymentInfoLinkQueryVariables>(UserWithPaymentInfoLinkDocument, options);
        }
export type UserWithPaymentInfoLinkQueryHookResult = ReturnType<typeof useUserWithPaymentInfoLinkQuery>;
export type UserWithPaymentInfoLinkLazyQueryHookResult = ReturnType<typeof useUserWithPaymentInfoLinkLazyQuery>;
export type UserWithPaymentInfoLinkQueryResult = Apollo.QueryResult<UserWithPaymentInfoLinkQuery, UserWithPaymentInfoLinkQueryVariables>;
export const UserWithPortalSessionDocument = gql`
    query UserWithPortalSession {
  portalSessionObject
}
    `;

/**
 * __useUserWithPortalSessionQuery__
 *
 * To run a query within a React component, call `useUserWithPortalSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserWithPortalSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserWithPortalSessionQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserWithPortalSessionQuery(baseOptions?: Apollo.QueryHookOptions<UserWithPortalSessionQuery, UserWithPortalSessionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserWithPortalSessionQuery, UserWithPortalSessionQueryVariables>(UserWithPortalSessionDocument, options);
      }
export function useUserWithPortalSessionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserWithPortalSessionQuery, UserWithPortalSessionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserWithPortalSessionQuery, UserWithPortalSessionQueryVariables>(UserWithPortalSessionDocument, options);
        }
export type UserWithPortalSessionQueryHookResult = ReturnType<typeof useUserWithPortalSessionQuery>;
export type UserWithPortalSessionLazyQueryHookResult = ReturnType<typeof useUserWithPortalSessionLazyQuery>;
export type UserWithPortalSessionQueryResult = Apollo.QueryResult<UserWithPortalSessionQuery, UserWithPortalSessionQueryVariables>;
export const VerifyAndUpdateSubscriptionDocument = gql`
    query VerifyAndUpdateSubscription($userId: String!, $subscriptionId: String!, $type: String!) {
  verifyAndUpdateSubscription(
    userId: $userId
    subscriptionId: $subscriptionId
    type: $type
  )
}
    `;

/**
 * __useVerifyAndUpdateSubscriptionQuery__
 *
 * To run a query within a React component, call `useVerifyAndUpdateSubscriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useVerifyAndUpdateSubscriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVerifyAndUpdateSubscriptionQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      subscriptionId: // value for 'subscriptionId'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useVerifyAndUpdateSubscriptionQuery(baseOptions: Apollo.QueryHookOptions<VerifyAndUpdateSubscriptionQuery, VerifyAndUpdateSubscriptionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VerifyAndUpdateSubscriptionQuery, VerifyAndUpdateSubscriptionQueryVariables>(VerifyAndUpdateSubscriptionDocument, options);
      }
export function useVerifyAndUpdateSubscriptionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VerifyAndUpdateSubscriptionQuery, VerifyAndUpdateSubscriptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VerifyAndUpdateSubscriptionQuery, VerifyAndUpdateSubscriptionQueryVariables>(VerifyAndUpdateSubscriptionDocument, options);
        }
export type VerifyAndUpdateSubscriptionQueryHookResult = ReturnType<typeof useVerifyAndUpdateSubscriptionQuery>;
export type VerifyAndUpdateSubscriptionLazyQueryHookResult = ReturnType<typeof useVerifyAndUpdateSubscriptionLazyQuery>;
export type VerifyAndUpdateSubscriptionQueryResult = Apollo.QueryResult<VerifyAndUpdateSubscriptionQuery, VerifyAndUpdateSubscriptionQueryVariables>;
export const VerifyBasiqConsentDocument = gql`
    query VerifyBasiqConsent($userId: String!) {
  verifyBasiqConsent(userId: $userId) {
    type
    size
    data {
      type
      id
      created
      updated
      expiryDate
      status
    }
  }
}
    `;

/**
 * __useVerifyBasiqConsentQuery__
 *
 * To run a query within a React component, call `useVerifyBasiqConsentQuery` and pass it any options that fit your needs.
 * When your component renders, `useVerifyBasiqConsentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVerifyBasiqConsentQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useVerifyBasiqConsentQuery(baseOptions: Apollo.QueryHookOptions<VerifyBasiqConsentQuery, VerifyBasiqConsentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VerifyBasiqConsentQuery, VerifyBasiqConsentQueryVariables>(VerifyBasiqConsentDocument, options);
      }
export function useVerifyBasiqConsentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VerifyBasiqConsentQuery, VerifyBasiqConsentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VerifyBasiqConsentQuery, VerifyBasiqConsentQueryVariables>(VerifyBasiqConsentDocument, options);
        }
export type VerifyBasiqConsentQueryHookResult = ReturnType<typeof useVerifyBasiqConsentQuery>;
export type VerifyBasiqConsentLazyQueryHookResult = ReturnType<typeof useVerifyBasiqConsentLazyQuery>;
export type VerifyBasiqConsentQueryResult = Apollo.QueryResult<VerifyBasiqConsentQuery, VerifyBasiqConsentQueryVariables>;